import { useEffect } from 'react';
import WtLoading from '../custom/WtLoading';
import React, { Component } from 'react';
import { login, loginFail } from '../store/authReducer';
import { useDispatch } from 'react-redux'
import WtHostPost from '../util/WtHostPost'
import WtTokenManager from '../util/WtTokenManager';

const WtUserInfoLoading = () => {
    const dispatch = useDispatch();
    let token=WtTokenManager.getToken()
    console.log(token)
    useEffect(() => {
        WtHostPost('/admin/refresh-token',{
			token:token
		},true).then(({json}: {json:any}) => {
			if(json.code === 0){
                WtTokenManager.setToken(json.data.token)
                dispatch(login(json.data));
			} else {
				throw json;
			}
            
		}).catch((error: any) => {
            dispatch(loginFail());
            localStorage.removeItem('try')
		});
    },[])

    return (
        <WtLoading/>
    );
}

export default WtUserInfoLoading;