import devConfig from './index.dev'
import proConfig from './index.pro'

const env = 'pro'+'';

let config = devConfig;
if(env === 'pro'){
    config = proConfig;
}

export default config;



