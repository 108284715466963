import {Breadcrumb} from "antd";
import { FC, ReactElement } from 'react';
import React, { Component } from 'react';
interface Props {
    secondLevel?: String,
    thirdLevel?: String,
    forthLevel?: String
}
const WtDefaultBreadcrumb: FC<Props> = (props): ReactElement => {
    const {
        secondLevel,
        thirdLevel,
        forthLevel
      } = props;
    return (
        <Breadcrumb>
            <Breadcrumb.Item>首页</Breadcrumb.Item>
            <Breadcrumb.Item>{secondLevel}</Breadcrumb.Item>
            {thirdLevel && <Breadcrumb.Item>{thirdLevel}</Breadcrumb.Item>}
            {forthLevel && <Breadcrumb.Item>{forthLevel}</Breadcrumb.Item>}
        </Breadcrumb>
    );
};

export default WtDefaultBreadcrumb;

