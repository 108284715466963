/**
 * Created by crazycooler on 2018/5/2.
 */
export default  {
    hostUrl : '/api/v1',
    tokenKey : 'userToken',
    appName : 'basic',
    fsHost : '',
    wsHost : 'http://192.168.0.102:3003',
    version : '1.0.0',
    withMock: false,
}



