import WtHostPost from '../util/WtHostPost';
import { useState,useEffect } from 'react';
import React, { Component } from 'react';
import WtDefaultBreadcrumb from "../custom/WtDefaultBreadcrumb";
import boxStyles from '../custom/boxStyles.module.less'
import styles from './userDetail.module.less';
import { useHistory,useParams } from 'react-router-dom';
import { Avatar,Descriptions,Table } from 'antd';
import WtNotification from '../util/WtNotification';
import { logout } from '../util/WtLogout';

const columns = [
    {
      title: '累计下单数',
      dataIndex: 'total_orders',
      key: 'total_orders',
    },
    {
      title: '累计下单金额',
      dataIndex: 'total_amount',
      key: 'total_amount',
    },
    {
      title: '实际下单数',
      dataIndex: 'actual_orders',
      key: 'actual_orders',
    },
    {
        title: '实际下单金额',
        dataIndex: 'actual_amount',
        key: 'actual_amount',
    },
      {
        title: '累计售后单数',
        dataIndex: 'address2',
        key: 'address',
      },
      {
        title: '累计售后金额',
        dataIndex: 'address3',
        key: 'address',
      },
];
const adressColumns=[
    {
        title: '收货人姓名',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: '收货人手机号',
        dataIndex: 'phone',
        key: 'phone',
    },
    {
        title: '收货地址',
        dataIndex: 'address',
        key: 'address',
        render:(text:string,record:any)=>{
            return text.split(',').join('')+record.address_detail
        }

    },
]

export default function UserDetail(){
    const histroy=useHistory()
    const urlParams=useParams() as any
    const [dataSource,setDataSource]=useState([
        {
            member_id:'',
            total_orders: '---',
            total_amount: '--',
            actual_orders: '--',
            actual_amount: '--',
            address2: '--',
            address3: '--',
        }
    ])
    const [userInfo,setUserInfo]=useState({
        avatar:'',
        created_at:'',
        id:'',
        level:'',
        mobile:'',
        nickname:'',
        source:'',
        status:'',
        addresses:[]
    }) as any
    const getMoneyData=()=>{
        WtHostPost(`/admin/member/order/${urlParams.id}`,{},true,'get').then(({json}: {json:any})=>{
            console.log(json)
            if(json.code===200){
                setDataSource([
                    {
                        ...json.data,
                        address2: '--',
                        address3: '--',
                    }
                ])
            }else if(json.code===422){
                WtNotification.warning(json.msg)
            }else if(json.code===401){
                WtNotification.error('登录过期,请重新登录');
                logout()
            }else{
                throw json;
            }
        }).catch((error:any)=>{
            WtNotification.error('系统出错,请联系管理员'+':'+error);
        })
    }
    console.log(urlParams)
   useEffect(()=>{
        getUserMessage()
   },[])

   const getUserMessage=()=>{
        WtHostPost(`/admin/member/detail/${urlParams.id}`,{},true,'get').then(({json}: {json:any})=>{
            console.log(json)
            if(json.code===200){
                setUserInfo(json.data)
            }else if(json.code===422){
                WtNotification.warning(json.msg)
            }else if(json.code===401){
                WtNotification.error('登录过期,请重新登录');
                logout()
            }else{
                throw json;
            }
        }).catch((error:any)=>{
            WtNotification.error('系统出错,请联系管理员'+':'+error);
        })
   }

    return (
        <div>
            <div className={boxStyles.main}>
                <div className={styles.title}>
                    基本信息
                </div>
                <div className={styles.basis}>
                    <div className={styles.avatar}>
                        <img src={userInfo.avatar==''?'https://thirdwx.qlogo.cn/mmopen/vi_32/POgEwh4mIHO4nibH0KlMECNjjGxQUq24ZEaGT4poC6icRiccVGKSyXwibcPq4BWmiaIGuG1icwxaQX6grC9VemZoJ8rg/132':userInfo.avatar}/>
                    </div>
                    
                    <div className={styles.userMessage}>
                        <Descriptions size={'default'}>
                            <Descriptions.Item label="用户ID">{userInfo.id}</Descriptions.Item>
                            <Descriptions.Item label="注册来源">{userInfo.source}</Descriptions.Item>
                            <Descriptions.Item label="邀请者"><a target='_blank' href={`#/user/userDetail/${userInfo.pid}`}>{userInfo.pid}</a></Descriptions.Item>
                            <Descriptions.Item label="用户昵称">{userInfo.nickname}</Descriptions.Item>
                            <Descriptions.Item label="手机号码">{userInfo.phone}</Descriptions.Item>
                            <Descriptions.Item label="用户等级">{userInfo.level}</Descriptions.Item>
                            <Descriptions.Item label="注册时间">{userInfo.created_at}</Descriptions.Item>
                        </Descriptions>
                    </div>
                </div>

                <div className={styles.title}>
                    消费信息&nbsp;&nbsp;(近三月)
                    <span onClick={getMoneyData}>查询</span>
                </div>

                <div>
                    <Table size='small' bordered dataSource={dataSource} columns={columns} rowKey={(record)=>(record.member_id)} pagination={false}/>
                </div>

                <div className={styles.title}>
                    地址信息
                </div>

                <div>
                    <Table bordered size='small' dataSource={userInfo.addresses} columns={adressColumns} rowKey={(record)=>(record.phone)} pagination={false}/>
                </div>
            </div>
        </div>
    )
}