/**
 * Created by coder on 2018/4/11.
 */
 import {notification} from 'antd'

 const defaultTitle = '温馨提示';

 //type 可选 success info warning error
 const notify = (type: 'success'|'info'|'warning'|'error',description: string,title=defaultTitle) => {
    notification[type]({
     message: title,
     description:description
   });
 };
 
 const success = function(description: string,title=defaultTitle){
   notify('success',description,title);
 };
 
 const info = function(description: string,title=defaultTitle){
   notify('info',description,title);
 };
 
 const warning = function(description: string,title=defaultTitle){
   notify('warning',description,title);
 };
 
 const error = function(description: string,title=defaultTitle){
   notify('error',description,title);
 };
 
 
 export default {
   success,info,warning,error
 };