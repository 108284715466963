import WtHostPost from '../util/WtHostPost';
import { Component } from 'react';
import React,{ useState,useEffect } from 'react';
import { FC, ReactElement } from 'react';
import WtDefaultBreadcrumb from "../custom/WtDefaultBreadcrumb";
import boxStyles from '../custom/boxStyles.module.less'
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import {logout} from '../util/WtLogout';
import { Input, Button, Tooltip, Row, Col, Space ,Modal,Pagination,Checkbox,Divider,Tag,Form,Radio,Spin,Select } from 'antd'
import {FormOutlined,PlusOutlined,DeleteOutlined,ExclamationCircleOutlined} from '@ant-design/icons';
import WtNotification from '../util/WtNotification';
const { confirm } = Modal;
const { TextArea } = Input;
const CheckboxGroup = Checkbox.Group;

interface Props {

}
const WtAuthority:FC<Props> = (props): ReactElement => {
    const [form] = Form.useForm();
    const [AddType,setAddType]=useState(false)
    const [loading,setLoading]=useState(false)
    const [checkedList, setCheckedList] = useState<Array<CheckboxValueType[]>>([]);

    const [checkAll, setCheckAll] = useState([] as any);
    const [apiGroup,setApiGroup]=useState([] as any)
    const [mouseId,getMouseId]=useState('' as any)
    const [groupId,setGroupId]=useState('' as any)
    const [itemId,setItemId]=useState('' as any)
    const [authorityModalOpen,setAuthorityModalOpen]=useState(false)
    useEffect(()=>{
        getAuthorityList()
    },[])


    

    const getAuthorityList=()=>{
        WtHostPost('/admin/merchant/apis/list',{},true,'get').then(({json}: {json:any})=>{
            if(json.code===200){
                setApiGroup(json.data.apigroup)
            }else if(json.code===422){
                WtNotification.warning(json.msg)
            }else if(json.code===401){
                WtNotification.error('登录过期,请重新登录');
                logout()
            }else{
                throw json;
            }
        }).catch((error: any)=>{
            WtNotification.error('系统出错,请联系管理员'+':'+error);
        })
    }

    const upDataAuthority=(item,groupId)=>{
        console.log(item,groupId)
        setItemId(item.id)
        setGroupId(groupId)
        setAddType(false)
        setAuthorityModalOpen(true)
        form.setFieldsValue({
            path:item.path,
            description:item.description,
            method:item.method,
            group_id:item.group_id
        })
    }

    const deleteAuthority=(item)=>{
        confirm({
            title: '你确定要删除该权限吗?',
            icon: <ExclamationCircleOutlined />,
            content: '一旦删除数据将无法恢复，你确定要删除吗?',
            onOk() {
                setLoading(true)
                WtHostPost('/admin/merchant/apis/del',{id:item.id},true).then(({json}: {json:any})=>{
                    if(json.code===200){
                        setLoading(false)
                        WtNotification.success('删除成功')
                        setAuthorityModalOpen(false)
                        getAuthorityList()
                    }else if(json.code===422){
                        setLoading(false)
                        WtNotification.warning(json.msg)
                    }else if(json.code===401){
                        WtNotification.error('登录过期,请重新登录');
                        logout()
                    }else{
                        setLoading(false)
                        throw json;
                    }
                }).catch((error: any)=>{
                    WtNotification.error('系统出错,请联系管理员'+':'+error);
                })
            },
          
          });
    }

    const addAuthority=(item)=>{
        setGroupId(item.id)
        form.setFieldsValue({
            path:'',
            method:'GET',
            description:''
        })
        setAddType(true)
        setAuthorityModalOpen(true)
    }

    const upDataAuthorityData=()=>{

    }

    const onFinish=(val)=>{
        if(AddType==true){
            let params={
                ...val,
                group_id:groupId
            }
            setLoading(true)
            WtHostPost('/admin/merchant/apis/add',params,true).then(({json}: {json:any})=>{
                if(json.code===200){
                    setLoading(false)
                    WtNotification.success('添加成功')
                    setAuthorityModalOpen(false)
                    getAuthorityList()
                }else if(json.code===422){
                    setLoading(false)
                    WtNotification.warning(json.msg)
                }else if(json.code===401){
                    WtNotification.error('登录过期,请重新登录');
                    logout()
                }else{
                    setLoading(false)
                    throw json;
                }
            }).catch((error: any)=>{
                WtNotification.error('系统出错,请联系管理员'+':'+error);
            })
        }else{
            let params={
                ...val,
                id:itemId,
            }
            setLoading(true)
            WtHostPost('/admin/merchant/apis/update',params,true).then(({json}: {json:any})=>{
                if(json.code===200){
                    setLoading(false)
                    WtNotification.success('修改成功')
                    setAuthorityModalOpen(false)
                    getAuthorityList()
                }else if(json.code===422){
                    setLoading(false)
                    WtNotification.warning(json.msg)
                }else if(json.code===401){
                    WtNotification.error('登录过期,请重新登录');
                    logout()
                }else{
                    setLoading(false)
                    throw json;
                }
            }).catch((error: any)=>{
                WtNotification.error('系统出错,请联系管理员'+':'+error);
            })
        }
      
    }
    return (
        <div>
            <div className={boxStyles.head}>
                <WtDefaultBreadcrumb
                    secondLevel="权限管理"
                    thirdLevel='权限管理'
                />
                    <h2>权限管理</h2>
                    <p>当前设置权限相关设置</p>
            </div>
                {
                    apiGroup.map((item,index)=>{
                        return <div className={boxStyles.main} key={index}>
                        <Spin tip="Loading..." spinning={loading}>
                        <div>
                        <div style={{fontSize:'17px',fontWeight:550}}>{item.name}</div>
                        <Divider style={{marginTop:'10px'}}/>
                            <span style={{marginRight:'10px',fontWeight:'bold'}}>权限:</span>
                           {item.apis.map((ele,index)=>{
                                return <span style={{display:'inline-block'}}>
                                        <span style={{display:'flex',alignItems:'center',width:'160px',whiteSpace:'nowrap',textOverflow:'ellipsis',overflow:'hidden'}} onMouseEnter={()=>{getMouseId(ele.id)}} onMouseLeave={()=>{getMouseId('')}}>
                                            <Tag color="warning">{ele.description}</Tag>
                                            {
                                                mouseId==ele.id&&<span>
                                                    <span style={{cursor:'pointer'}} onClick={()=>{upDataAuthority(ele,item.id)}}><FormOutlined /></span>
                                                    <span style={{cursor:'pointer',marginLeft:'10px'}} onClick={()=>{deleteAuthority(ele)}}><DeleteOutlined/></span>
                                                </span>
                                                
                                            
                                            }
                                        </span>
                                </span>
                           })}
                      
                        <Tag style={{cursor:'pointer'}} onClick={()=>{addAuthority(item)}} icon={<PlusOutlined />} color="processing">新增权限</Tag>
                    </div>
                    </Spin>
            </div>
                    })
                }
            <Modal title={AddType==true?'新增权限':'编辑权限'} open={authorityModalOpen} footer={false} onCancel={()=>{setAuthorityModalOpen(false)}}>
                <div>
                    <Form
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 20 }}
                        layout="horizontal"
                        form={form}
                        onFinish={onFinish}
                        initialValues={{
                            hidden:0,
                        }}
                    >
                         
                        <Form.Item name="path" label="路径" rules={[{ required: true }]}>
                            <Input placeholder='请输入路径'/>
                        </Form.Item>

                        <Form.Item name="description" label="中文说明" >
                            <TextArea placeholder='请输入中文说明' rows={4} />
                        </Form.Item>

                        {
                            AddType==false&&<Form.Item name="group_id" label="选择权限组" >
                              <Select
                                    options={apiGroup.map(ele1=>{
                                        return {
                                            label: ele1.name,
                                            value: ele1.id
                                        }
                                    })}
                                />
                        </Form.Item>
                        }

                        <Form.Item name='method' label="请求方法">
                            <Radio.Group 
                                options={
                                    [
                                        { label: 'GET', value:'GET'},
                                        { label: 'POST', value: 'POST' }
                                    ]
                                }
                                buttonStyle="solid"
                                optionType="button" />
                        </Form.Item>
        
                        <Form.Item style={{textAlign:'right',width:'100%'}}>
                            <Button type="primary" htmlType="submit">确定</Button>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </div>
    )
}
export default WtAuthority