import React from 'react';
import ReactDOM from 'react-dom';
import './index.less';
import WtRoute from './route/WtRoute';
import reportWebVitals from './reportWebVitals';
import {store} from './store'
import {Provider} from 'react-redux'
import config from './config'

if(config.withMock){
  import('./mock').then(() => {})
}


ReactDOM.render(
  <>
    <Provider store={store}>
      <WtRoute/>
    </Provider>
  </>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
