import { useState } from 'react';
import React, { Component } from 'react';
import { Avatar,Dropdown,Space,Menu  } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import {Switch, Route, useHistory}  from 'react-router-dom'
import type { ProSettings } from '@ant-design/pro-layout';
import ProLayout, { SettingDrawer } from '@ant-design/pro-layout';
import defaultProps from './WtAntProLayoutDefaultProps';
import logo from '../static/logo.png';
import WtAdminHome from '../admin/WtAdminHome';
import WtUserManage from '../user/userManage'
import type { MenuProps } from 'antd'
import WtUserDetail from '../user/userDetail'
import WtMerchantManage from '../merchant/merchantManage'
import WtMerchantDetail from '../merchant/WtMerchantDetail';
import WtAddOrUpdateMer from '../merchant/WtAddOrUpdateMer';
import WtAccountManage from '../option/accountManage'
import WtAssort from '../assort/WtAssort'
import { createFromIconfontCN } from '@ant-design/icons'
import WtUserLevelManage from '../authority/WtUserLevelManage'
import WtMenusManage from '../authority/WtMenusManage'
import WtAuthorityGroup from '../authority/WtAuthorityGroup';
import WtAuthority from '../authority/WtAuthority';
import WtCompanyManage from '../company/WtCompanyManage'
import WtCompanyAuthority from '../company/WtAuthority'
import WtConpantAuthorityList from '../company/WtAuthorityGroup'
import { logout } from '../util/WtLogout';

const IconFont = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/c/font_4124201_er56cega7wh.js',
});


const WtAntProLayout = () => {
  const [settings, setSetting] = useState<Partial<ProSettings> | undefined>({ fixSiderbar: true });
  const [pathname, setPathname] = useState('/admin');
  const history = useHistory();
  const items: MenuProps['items'] = [
    {
      label: (
        <a onClick={()=>{logout()}}>
            切换账号
        </a>
      ),
      key: '0',
    },
    {
      label: (
        <a onClick={()=>{logout()}}>
            退出登录
        </a>
      ),
      key: '1',
    }
  ];
  return (
    <div
      id="test-pro-layout"
      style={{
        height: '100vh',
      }}
    >
      <ProLayout
        {...defaultProps}
        location={{
          pathname,
        }}
        title='后台管理'
        waterMarkProps={{
          content: 'Pro Layout',
        }}
        logo={logo}
        menuFooterRender={(props) => {
          return (
            <a
              style={{
                lineHeight: '48rpx',
                display: 'flex',
                height: 48,
                color: 'rgba(255, 255, 255, 0.65)',
                alignItems: 'center',
              }}
              href="https://preview.pro.ant.design/dashboard/analysis"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="pro-logo"
                src={logo}
                style={{
                  width: 16,
                  height: 16,
                  margin: '0 16px',
                  marginRight: 10,
                }}
              />
              {!props?.collapsed && '后台管理'}
            </a>
          );
        }}
        onMenuHeaderClick={(e) => console.log(e)}
        menuItemRender={(TWTProps, defaultDom) => {
          return (
              <a onClick={() => {
                setPathname(TWTProps.path || '/welcome');
                history.push(TWTProps.path || '/admin');
              }}>
                  {TWTProps.name==='欢迎'?"":TWTProps.icon}{defaultDom}
              </a>
          )
      }}
        subMenuItemRender={(TWTProps) => {
          return (
                  <>
                      <span className="ant-pro-menu-item">
                          <span role="img" className="anticon">
                              {TWTProps.icon}
                          </span>

                          <span>
                              {TWTProps.name}
                          </span>
                      </span>
                  </>
              )
       }}
        rightContentRender={() => {
          let userInfo=JSON.parse(localStorage.getItem('userInfo') as any)
          return <div style={{display:'flex',alignItems:'center'}}>
            <Avatar style={{marginRight:'10px'}} shape="square" size="small" icon={<UserOutlined />} />
              <Dropdown menu={{items}}>
                <Space onClick={e => e.preventDefault()}>
                  {userInfo.nick_name}
                </Space>
              </Dropdown>
        
          </div>
        }}
        {...settings}
      >
        <Switch>
            <Route path='/admin' exact><WtAdminHome/></Route>
            <Route path='/user/userManage' exact><WtUserManage/></Route>
            <Route path='/user/userDetail/:id' exact><WtUserDetail/></Route>
            <Route path='/merchant/merchantManage' exact><WtMerchantManage/></Route>
            <Route path='/merchant/merchantDetail/:id' exact><WtMerchantDetail/></Route>
            <Route path='/merchant/WtAddOrUpdateMer/:id' exact><WtAddOrUpdateMer/></Route>
            <Route path='/option/accountManage' exact><WtAccountManage/></Route>
            <Route path='/product/assort' exact><WtAssort/></Route>
            <Route path='/authority/level' exact><WtUserLevelManage/></Route>
            <Route path='/authority/menu' exact><WtMenusManage/></Route>
            <Route path='/authority/authority' exact><WtAuthority/></Route>
            <Route path='/authority/authorityGroup' exact><WtAuthorityGroup/></Route>
            <Route path='/company/companyManage' exact><WtCompanyManage/></Route>
            <Route path='/company/authority' exact><WtCompanyAuthority/></Route>
            <Route path='/company/authorityGroup' exact><WtConpantAuthorityList/></Route>
        </Switch>
      </ProLayout>
      <SettingDrawer
        pathname={pathname}
        enableDarkTheme
        getContainer={() => document.getElementById('test-pro-layout')}
        settings={settings}
        onSettingChange={(changeSetting) => {
          setSetting(changeSetting);
        }}
        disableUrlParams={false}
      />
    </div>
  );
};

export default WtAntProLayout;