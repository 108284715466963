import {useState} from 'react'
import React, { Component } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { login } from '../store/authReducer'
import { Button, Form, Input, Checkbox } from 'antd';
import { RootState } from '../store';
import {useHistory, Link} from 'react-router-dom'
import {LockOutlined,UserOutlined} from '@ant-design/icons'
import WtHostPost from '../util/WtHostPost';
import WtTokenManager from '../util/WtTokenManager';
import WtNotification from '../util/WtNotification';
import './WtLogin.css'

const FormItem = Form.Item;

const WtLogin = () => {
    const auth = useSelector((state: RootState) => state.auth);
    const history = useHistory();
    const dispatch = useDispatch();
    const [submitting, setSubmitting] = useState(false);

    const handleFinish = (values: any) => {
        console.log('handleFinish',values);
        setSubmitting(true);
		WtHostPost('/admin/login',{
			username:values.username,
			password:values.password,
		}).then(({json}: {json:any}) => {
            console.log(json)
			if(json.code === 200){
				WtTokenManager.setToken(json.data.token,values.remember);
                localStorage.setItem('userInfo',JSON.stringify(json.data))
                dispatch(login(json.data));
			} else if(json.code === 422){
                WtNotification.error(json.msg);
            }else {
				throw json;
			}
            setSubmitting(false);
		}).catch((error: any) => {
			WtNotification.error('用户名或密码错误');
            setSubmitting(false);
		});
    };

    const handleFinishFailed = (errorInfo: any) => {
        console.log('handleFinishFailed',errorInfo);
    };

    return (
        <div style={{ height: '100%' }} >
            <div style={{ height: '100px' }} />
            <div className="wt-login wt-auto-shadow">
                <h3 className="title">后台管理</h3>
                <Form 
                    onFinish={handleFinish}
                    onFinishFailed={handleFinishFailed} 
                    className="login-form"
                >
                    <FormItem
                        style={{padding:'4px 0'}}
                        name='username'
                        rules={[
                            { required: true, message: '请输入用户名' },
                            // { validator: WtValidate.validateEmail }
                        ]}
                    >
                        <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }}/>} placeholder="用户名" />
                    </FormItem>
                    <FormItem
                        style={{padding:'4px 0'}}
                        name='password'
                        rules={[{ required: true, message: '请输入密码!' }]}
                    >
                        <Input prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }}/>} type="password" placeholder="密码" />
                    </FormItem>
                    
                    <FormItem>
                        <FormItem
                            style={{marginBottom:'14px'}}
                            name='check'
                            initialValue={true}
                            valuePropName="checked"
                            noStyle
                        >
                            <Checkbox>自动登录</Checkbox>
                        </FormItem>
                        {/* <Link className="login-form-forgot" to="/forgot-password">忘记密码</Link> */}
                    </FormItem>
                    
                    
                    <FormItem>
                        <Button loading={submitting} type="primary" htmlType="submit" className="login-form-button">
                            登录
                        </Button>
                    </FormItem>
                </Form>
            </div>
        </div>

    );
}

export default WtLogin;