export const ACTION_COUNT ={
	increment:"ACTION_COUNT.increment",
    decrement:'ACTION_COUNT.decrement'
}

const initialState = {
    count: 0,
};


export default (state = initialState, action: any) => {
    let count=state.count
    switch(action.type){
        case ACTION_COUNT.increment:
            return {
                count:count+1
            };
        case ACTION_COUNT.decrement:
            return {
                count:count-1
            };
        default:
            return state;
    }
}
