import WtHostPost from '../util/WtHostPost';
import { Component } from 'react';
import React,{ useState,useEffect } from 'react';
import { FC, ReactElement } from 'react';
import WtDefaultBreadcrumb from "../custom/WtDefaultBreadcrumb";
import boxStyles from '../custom/boxStyles.module.less'
import type { ColumnsType } from 'antd/es/table';
import {logout} from '../util/WtLogout';
import { Input, Button, InputNumber,Upload, Space, Table,Switch, Form,Badge,Modal, Pagination } from 'antd'
import {LoadingOutlined,ExclamationCircleOutlined,PlusOutlined} from '@ant-design/icons';
import WtNotification from '../util/WtNotification';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import getBase64 from '../util/WtUpLoadImg'
import request from '../util/WtAxios'
const { confirm } = Modal;
interface Props {

}
interface DataType {
    id: string,
    name: string,
    level: string,
    status:number,
    created_at:string,
}


const WtAssort:FC<Props> = (props): ReactElement => {
    const [loading,setLoading]=useState(false)
    const [AddType,setAddType]=useState(true)
    const [sortModalOpen,setSortModalOpen]=useState(false)
    const [form] = Form.useForm();
    const [record,setRecord]=useState({} as any)
    const [TitleImageUrl,setTitleImageUrl]=useState('')
    const [ImageUrl,setImageUrl]=useState('')
    const tableChange=(pagination:any)=>{
        let pager = pagination;
        pager.current = pagination.current;
        pager.pageSize=pagination.pageSize
        setPagination({...pager})
    }
    const [dataSource,setDataSource]=useState([])
    const [Pagination, setPagination] = useState({
        showQuickJumper:true,
        showSizeChanger:true,
        pageSize:2,
        current:1,
        pageSizeOptions: ['2','5','10','20','50'],
        total:0
    });

    const columns: ColumnsType<DataType> = [
    {
      title: '分类名称',
      dataIndex: 'name',
      key: 'name',
      align:'center',
    },
    {
      title: '分类状态',
      dataIndex: 'status',
      align:'center',
      key: 'status',
      render(text) {
        if(text===1){
            return <Badge key='green' color='green' text='正常'/>
        }else if(text===0){
            return  <Badge key='red' color='red' text='禁用'/>
        }
      }
    },
    {
        title: '创建时间',
        dataIndex: 'created_at',
        align:'center',
        key: 'created_at',
    },
    {
      title: '操作',
      key: 'option',
      align:'center',
      render: (_, record:any) => {
        if(record.level===0||record.level===1){
            return <Space size="middle">
                <a onClick={()=>{addSort(record)}}>新增子分类</a>
                <a onClick={()=>{getSortDetail(record)}}>编辑</a>
                <a onClick={()=>{changeUserStatus(record)}}>{record.status===1?'禁用':'启用'}</a>
              </Space>   
        }else{
            return <Space size="middle">
            <a onClick={()=>{getSortDetail(record)}}>编辑</a>
            <a onClick={()=>{changeUserStatus(record)}}>{record.status===1?'禁用':'启用'}</a>
          </Space>
        }
    },
    },
];

    useEffect(()=>{
        getSortList()
    },[Pagination])

    //递归删除所有没有子类的children属性
    const dealData=(data)=>{
        for(let i=0;i<data.length;i++){
            if(data[i].children.length===0){
                delete data[i].children
            }else{
                dealData(data[i].children)
            }
        }
        return data
    }
    //递归增加所有分类的等级
    const addLevel=(data,level)=>{
        if(level==3){
            return data
        }else{
            for(let i=0;i<data.length;i++){
                data[i].level=level
                if(data[i].children){
                    data[i].children=addLevel(data[i].children,level+1)
                }
            } 
            return data
        }
    }
    const addSort=(record:any)=>{
        form.setFieldsValue({
            name:'',
            sort:'',
            status:true,
        })
        setImageUrl('')
        setTitleImageUrl('')
        setSortModalOpen(true)
        setRecord(record)
        setAddType(true)
    }

    const getSortDetail=(record)=>{
        WtHostPost(`/admin/category/one/${record.id}`,{},true,'get').then(({json}: {json:any})=>{
            if(json.code===200){
                form.setFieldsValue({
                    name:json.data.name,
                    sort:json.data.sort,
                    status:json.data.status===1?true:false,
                })
                setImageUrl(json.data.logo)
                setTitleImageUrl(json.data.logo)
                setRecord(record)
                setSortModalOpen(true)
                setAddType(false)
            }else if(json.code===422){
                WtNotification.warning(json.msg)
            }else if(json.code===401){
                WtNotification.error('登录过期,请重新登录');
                logout()
            }else{
                throw json;
            }
        }).catch((error: any)=>{
            WtNotification.error('系统出错,请联系管理员'+':'+error);
        })
    }

    const getSortList=()=>{
        let params={
                page: Pagination.current,
                pageSize: Pagination.pageSize,
        }
        setLoading(true)
        WtHostPost('/admin/category/list',{},true).then(({json}: {json:any})=>{
            if(json.code===200){
                let dataSource=dealData(json.data.data)
                dataSource=addLevel(dataSource,0)
                setDataSource(json.data.data)
                let newPag=Pagination
                newPag.total = json.data.total;
                setPagination(newPag)
                setLoading(false)
            }else if(json.code===422){
                WtNotification.warning(json.msg)
            }else if(json.code===401){
                WtNotification.error('登录过期,请重新登录');
                logout()
            }else{
                throw json;
            }
        }).catch((error: any)=>{
            WtNotification.error('系统出错,请联系管理员'+':'+error);
        })
    }

    const changeUserStatus = (record:any)=>{
        console.log(record)
        confirm({
            title: '状态修改',
            icon: <ExclamationCircleOutlined />,
            content: record.status===0?'您确定要启用该用户吗状态吗?':'您确定要禁用该用户吗状态吗?',
            onOk:()=>{
              changeStatus(record)
            },
          });
    }

    const uploadButton = (
        <div>
          {loading ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>上传</div>
        </div>
    );

    const onFinish=(val)=>{
        console.log(record)
        let params
        if(AddType===true){
            params={
                sort:val.sort,
                logo:TitleImageUrl,
                pid:record.id?record.id:0,
                name:val.name,
                status:val.status===true?1:0
            }
            console.log(val,params)
            WtHostPost('/admin/category/one',params,true).then(({json}: {json:any})=>{
                if(json.code===200){
                    WtNotification.success('添加成功')
                    setSortModalOpen(false)
                    getSortList()
                }else if(json.code===422){
                    WtNotification.warning(json.msg)
                }else if(json.code===401){
                    WtNotification.error('登录过期,请重新登录');
                    logout()
                }else{
                    throw json;
                }
            }).catch((error:any)=>{
                WtNotification.error('系统出错,请联系管理员'+':'+error);
            })

        }else{
            params={
                id:record.id,
                name:val.name,
                status:val.status==true?1:0,
                logo:TitleImageUrl,
                pid:record.pid,
                sort:val.sort
            }
            WtHostPost('/admin/category/edit',params,true).then(({json}: {json:any})=>{
                if(json.code===200){
                    WtNotification.success('修改成功')
                    setSortModalOpen(false)
                    getSortList()
                }else if(json.code===422){
                    WtNotification.warning(json.msg)
                }else if(json.code===401){
                    WtNotification.error('登录过期,请重新登录');
                    logout()
                }else{
                    throw json;
                }
            }).catch((error:any)=>{
                WtNotification.error('系统出错,请联系管理员'+':'+error);
            })
        }
    }
    const beforeUpload=(file: RcFile)=>{
        console.log(uploadImage)
        getBase64(file,(url)=>{
            setImageUrl(url)
            uploadImage(file)
        })
    }

    const uploadImage=(file: RcFile)=>{
        let token=localStorage.getItem('userToken')
        let formData = new window.FormData(); 
        formData.append('file',file);
        request({
            url:'/api/1/v1/common/file/upload',
            method:'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data',
                'authorization': 'Bearer'+ ' '+token
            },
            data:formData
        }).then((response) => {
           if(response.data.code===200){
                let params={
                    parent_id:0,
                    repository_identity:response.data.data.identity,
                    ext:response.data.data.ext,
                    name:response.data.data.name,
                }
                WtHostPost('/common/repository/save',params,true,'post','/api/1/v1').then(({json}: {json:any})=>{
                    if(json.code===200){
                        setTitleImageUrl(json.data.url)
                    }else if(json.code===422){
                        WtNotification.warning(json.msg)
                    }else if(json.code===401){
                        WtNotification.error('登录过期,请重新登录');
                        logout()
                    }else{
                        throw json;
                    }
                })
           }
        })
    }

    const changeStatus=(record)=>{
        WtHostPost('/admin/category/disable',{
            id:record.id,
            status:record.status===0?1:0
        },true).then(({json}: {json:any})=>{
            if(json.code===200){
                WtNotification.success('修改成功')
                getSortList()
            }else if(json.code===422){
                WtNotification.warning(json.msg)
            }else if(json.code===401){
                WtNotification.error('登录过期,请重新登录');
                logout()
            }else{
                throw json;
            }
        }).catch((error:any)=>{
            WtNotification.error('系统出错,请联系管理员'+':'+error);
        })
    }
    return (
        <div>
            <div className={boxStyles.head}>
                <WtDefaultBreadcrumb
                    secondLevel="商品管理"
                    thirdLevel='分类管理'
                />
                    <h2>分类管理</h2>
                    <p>当前设置分类相关设置</p>
            </div>
            <div className={boxStyles.main}>
                <div style={{textAlign:'right',marginBottom:'10px'}}><Button onClick={()=>{addSort({})}} type="primary">新增</Button></div>
                <Table bordered loading={loading}  onChange={tableChange} columns={columns} dataSource={dataSource} key='' rowKey={record=>record.id} pagination={Pagination}/>
            </div>

                    {/* 用户编辑新增弹窗 */}
            <Modal title={AddType==true?'新增分类':'编辑分类'} open={sortModalOpen} footer={false} onCancel={()=>{setSortModalOpen(false)}}>
                <div>
                    <Form
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 20 }}
                        layout="horizontal"
                        form={form}
                        onFinish={onFinish}
                    >
                        <Form.Item name="name" label="标题名称" rules={[{ required: true }]}>
                            <Input placeholder='请输入标题名称'/>
                        </Form.Item>
                         
                        <Form.Item name="sort" label="排序">
                            <InputNumber min={0}/>
                        </Form.Item>
        
                        <Form.Item name="titleImage" label="封面">
                            <Upload
                                name="titleImage"
                                listType="picture-card"
                                showUploadList={false}
                                beforeUpload={beforeUpload}
                                fileList={[] as any}
                                >
                                {ImageUrl ? <img src={ImageUrl} alt="avatar" style={{ width: '100%',height:"100%" }} /> : uploadButton}
                            </Upload>
                        </Form.Item>

                        <Form.Item name='status' label="状态">
                            <Switch defaultChecked/>
                        </Form.Item>
        
                        <Form.Item style={{textAlign:'right',width:'100%'}}>
                            <Button type="primary" htmlType="submit">确定</Button>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
            {/* 分类编辑新增弹窗 */}

        </div>
    )
}
export default WtAssort