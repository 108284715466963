/**
 * Created by coder on 2017/2/23.
 */
import config from '../config'
import TokenManager from './WtTokenManager'
import { logout } from './WtLogout'
import request from './WtAxios'
import {AxiosResponseHeaders} from 'axios'

const { hostUrl } = config;


interface WtHostPostResponse<T = any>{
    json: T;
    header: AxiosResponseHeaders;
    status: number;
}

function WtHostPost(url: string, data: any, needToken = false, type='post',prefix = hostUrl): any {
    const token = needToken ? TokenManager.getToken() : null;
    if (needToken && !token) {
        //没有找到token
        console.error('not found token');
        return new Promise((resolve, reject) => { reject({ code: 1401, msg: 'not found token' }) });
    }

    if (needToken && TokenManager.isRefreshing()) {
        //如果发现token正在刷新，则等待4s,否则清空token
        return setRefreshTokenTimeout().then((json) => {
            return WtHostPost(url, data, needToken);
        }).catch((error) => {
            console.log(error);
        });
    }

    console.log('send', data);
    console.log('to', url);
    return request({
        url:prefix + url,
        method: type as any,
        headers: {
            'Content-Type': 'application/json',
            'authorization': 'Bearer'+ ' '+token
        },
        data:JSON.stringify(data)
    }).then((response) => {
        return DefaultCheckPost({json:response.data,header:response.headers,status:response.status}, url, data, needToken, token!);
    })
}

function setRefreshTokenTimeout() {
    return new Promise((resolve, reject) => {
        //设置token更新超时
        let count = 0;
        let waitTime = setInterval(() => {
            count++;
            if (!TokenManager.isRefreshing()) {
                clearInterval(waitTime);
                return resolve({ token: TokenManager.getToken() });
            }
            if (count > 20) {
                clearInterval(waitTime);
                console.error('wait refresh token timeout');
                logout();
                return reject({ code: 1402, msg: 'wait refresh token timeout' });
            }
        }, 200);
    });
}


function RefreshToken() {
    if (TokenManager.isRefreshing()) {
        return setRefreshTokenTimeout();
    } else {
        //开始刷新token，设置为waiting，防止重复刷新
        TokenManager.setRefreshing(true);
        return request({
            url:hostUrl + '/auth/refresh',
            method:'post',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({ token: TokenManager.getToken() }),
        }).then((response) => {
            const json = response.data;
            if (json.code === 0) {
                TokenManager.setToken(json.token);
                TokenManager.setRefreshing(false);
                return json;
            } else {
                console.error('RefreshToken error json:', json);
                logout();
                return new Promise((resolve, reject) => reject({ code: 1403, msg: 'refresh token fail', json: json }));
            }
        }).catch((e) => {
            console.error('RefreshToken error:', e);
            logout();
            return new Promise((resolve, reject) => reject({ code: 1403, msg: 'refresh token fail' }));
        })
    }
}

function DefaultCheckPost(response: WtHostPostResponse, url: string, data: any, needToken: boolean, token: string) {
    const {json} = response;
    console.log('fetch', json);
    if (needToken) {
        if (json.code === 401 && json.msg === 'token_expired') {
            return RefreshToken().then((json) => {
                return WtHostPost(url, data, needToken);
            }).catch((error) => {
                console.error(error);
                logout();
            });
        } else if (json.code === 401 && json.msg === 'token_invalid') {
            console.error('token_invalid: ' + token);
            logout();
        } else if (json.code === 401) {
            logout();
        }
    }

    return response;
}

export default WtHostPost;