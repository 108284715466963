import WtHostPost from '../util/WtHostPost';
import { Component } from 'react';
import React,{ useState,useEffect } from 'react';
import { FC, ReactElement } from 'react';
import WtDefaultBreadcrumb from "../custom/WtDefaultBreadcrumb";
import boxStyles from '../custom/boxStyles.module.less'
import { useHistory } from 'react-router-dom';
import type { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import {logout} from '../util/WtLogout';
import { Input, Button, Tooltip, Row, Col,Select, Space, Table, Tag  ,DatePicker,Badge,Modal, Pagination } from 'antd'
import {CaretDownOutlined, CaretUpOutlined, RedoOutlined,FileTextOutlined, SearchOutlined,ExclamationCircleOutlined} from '@ant-design/icons';
import WtNotification from '../util/WtNotification';
import 'moment/locale/zh-cn';
const Option = Select.Option;
const { RangePicker } = DatePicker;
const { confirm } = Modal;
const dateFormat = 'YYYY-MM-DD';
interface Props {

}
interface DataType {
    id: string,
    nickname: string,
    mobile:string,
    level: string,
    status:string,
    source:string,
    created_at:string,
    avatar:string
}

type SearchType = {
    phone:string,
    source:string | undefined,
    nickname:string,
    startTime:string,
    endTime:string
}
const UserManage:FC<Props> = (props): ReactElement => {

    const [identity,setIdentity]=useState('')
    const [changeSearchState,setChangeSearchState]=useState<Boolean>(false)
    const [time,setTime]=useState() as any
    const [phone,setPhone]=useState('') as any
    const [source,setSource]=useState(undefined) as any
    const [nickname,setNickname]=useState('') as any
    const [startTime,setStartTime]=useState('') as any
    const [endTime,setendTime]=useState('') as any
    const history=useHistory()
    const [loading,setLoading]=useState(false)
    const tableChange=(pagination:any)=>{
        console.log(pagination)
        let pager = pagination;
        pager.current = pagination.current;
        pager.pageSize=pagination.pageSize
        setPagination({...pager})
    }
    const [searchData,setSearchData]=useState<SearchType>({phone:'',source:undefined,nickname:'',startTime:'',endTime:''})
    const [dataSource,setDataSource]=useState([])
    const [Pagination, setPagination] = useState({
        showQuickJumper:true,
        showSizeChanger:true,
        pageSize:10,
        current:1,
        pageSizeOptions: ['10', '20', '50', '100'],
        total:0
    });

    const columns: ColumnsType<DataType> = [
    {
      title: '用户ID',
      dataIndex: 'id',
      key: 'id',
      align:'center',
      render: (text) => <a>{text}</a>,
    },
    {
      title: '用户头像',
      dataIndex: 'avatar',
      align:'center',
      key: 'avatar',
      render(value, record, index) {
        if(value===''){
            return <img style={{width:'50px',height:'50px',borderRadius:'50% 50%'}} src='https://thirdwx.qlogo.cn/mmopen/vi_32/POgEwh4mIHO4nibH0KlMECNjjGxQUq24ZEaGT4poC6icRiccVGKSyXwibcPq4BWmiaIGuG1icwxaQX6grC9VemZoJ8rg/132'/>
        }else{
            return <img style={{width:'50px',height:'50px',borderRadius:'50% 50%'}} src={value}/>
        }
          
      },
    },
    {
      title: '用户昵称',
      dataIndex: 'nickname',
      align:'center',
      key: 'nickname',
    },
    {
        title: '手机号码',
        dataIndex: 'mobile',
        align:'center',
        key: 'mobile',
    },
    {
        title: '用户等级',
        dataIndex: 'level',
        align:'center',
        key: 'level',
    },
    {
        title: '账号状态',
        dataIndex: 'status',
        align:'center',
        key: 'status',
        render(text:number,record:any){
            if(text===0){
                return <Badge key='green' color='green' text='启用'/>
            }else if(text===10){
                return  <Badge key='red' color='red' text='禁用'/>
            }else if(text===20){
                return  <Badge key='gray' color='gary' text='注销'/>
            }else{
                return  <Badge key='#000' color='#000' text='已删除'/>
            }
        }
    },
    {
        title: '注册来源',
        align:'center',
        dataIndex: 'source',
        key: 'source',
        render:(text:any)=>{
            if(text=='wx'){
                return '微信'
            }else if(text==='merchant'){
                return '商户后台'
            }else if(text==='android'){
                return "安卓app"
            }else if(text==='ios'){
                return "苹果app"
            }else if(text==='H5'){
                return "H5"
            }else{
                return '未知'
            }
            
        }
    },
    {
        title: '注册时间',
        dataIndex: 'created_at',
        align:'center',
        key: 'created_at',
    },
    {
      title: '操作',
      key: 'option',
      render: (_, record:any) => {
        if(record.status===0){
            return <Space size="middle">
                <a onClick={()=>{history.push(`/user/userDetail/${record.id}`)}}>详情</a>
                <a onClick={()=>{changeUserStatus(record)}}>禁用</a>
              </Space>   
        }else if(record.status===10){
            return <Space size="middle">
                <a onClick={()=>{history.push(`/user/userDetail/${record.id}`)}}>详情</a>
                <a onClick={()=>{changeUserStatus(record)}}>启用</a>
              </Space> 
        }else{
            return <Space size="middle">
                <a onClick={()=>{history.push(`/user/userDetail/${record.id}`)}}>详情</a>
              </Space> 
        }
    },
    },
];

    useEffect(()=>{
        getUserList()
    },[Pagination,searchData])

    const getUserList=()=>{
        let params={
                page: Pagination.current,
                pageSize: Pagination.pageSize,
                nickname: searchData.nickname,
                source: searchData.source,
                mobile: searchData.phone,
                start: searchData.startTime,
                end: searchData.endTime
        }
        setLoading(true)
        WtHostPost('/admin/member/list',{...params},true).then(({json}: {json:any})=>{
            console.log(json)
            if(json.code===200){
                setDataSource(json.data.data)
                let newPag=Pagination
                newPag.total = json.data.total;
                setPagination(newPag)
                setLoading(false)
            }else if(json.code===422){
                WtNotification.warning(json.msg)
            }else if(json.code===401){
                WtNotification.error('登录过期,请重新登录');
                logout()
            }else{
                throw json;
            }
        }).catch((error: any)=>{
            WtNotification.error('系统出错,请联系管理员'+':'+error);
        })
    }

    const clear= ()=>{
        setSearchData({phone:'',source:undefined,nickname:'',startTime:'',endTime:''})
        setNickname('')
        setPhone('')
        setStartTime('')
        setendTime('')
        setSource(undefined)
        setTime(undefined)
        let newPag=Pagination
        newPag.current=1
        setPagination(newPag)
    }

    const handleSearch=()=>{
        let newPag=Pagination
        newPag.current=1
        setPagination(newPag)
        setSearchData({phone,source,nickname,startTime,endTime})
    }

    const flip_expandSearchPanel=()=>{
        setChangeSearchState(!changeSearchState)
    }

    const onChangeTime=(value:any,dateString:any)=>{
        setTime(value)
        setStartTime(dateString[0])
        setendTime(dateString[1])
    }
    const changeUserStatus = (record:any)=>{
        console.log(record)
        confirm({
            title: '状态修改',
            icon: <ExclamationCircleOutlined />,
            content: record.status===10?'您确定要启用该用户吗状态吗?':'您确定要禁用该用户吗状态吗?',
            onOk:()=>{
              changeStatus(record)
            },
          });
    }

    const changeStatus=(record)=>{
        WtHostPost('/admin/member/status',{
            id:record.id,
            status:record.status===0?10:0
        },true).then(({json}: {json:any})=>{
            if(json.code===200){
                WtNotification.success('修改成功')
                getUserList()
            }else if(json.code===422){
                WtNotification.warning(json.msg)
            }else if(json.code===401){
                WtNotification.error('登录过期,请重新登录');
                logout()
            }else{
                throw json;
            }
        }).catch((error:any)=>{
            WtNotification.error('系统出错,请联系管理员'+':'+error);
        })
    }
    return (
        <div>
            <div className={boxStyles.head}>
                <WtDefaultBreadcrumb
                    secondLevel="用户管理"
                    thirdLevel='用户管理'
                />
                    <h2>用户管理</h2>
                    <p>当前设置用户相关设置</p>
            </div>
            <div className={boxStyles.search}>
            <Row
                  
                    justify = "end"
                >
                <Col
                    span={7}
                    style={{textAlign:'right'}}
                >
                    <span style={{margin:'0 10px'}}>注册来源</span>
                        <Select 
                            style={{ width:'72%',textAlign:'left'}} 
                            value={source}
                            onChange={val=>setSource(val)}
                            placeholder="请选择"
                            optionFilterProp="children"
                        >
                            <Option value="APP">APP</Option>
                            <Option value="H5">H5</Option>
                            <Option value="小程序">小程序</Option>
                        </Select>
                    </Col>
                <Col
                        span={7}
                       style={{textAlign:'right'}}
                   >
                       <span>用户昵称</span>
                       <Input
                           style={{
                               marginLeft:10,
                               width:"72%"
                           }}
                           placeholder="  请输入用户昵称"
                           value={nickname}
                           onChange={e=>setNickname(e.target.value)}
                       />
                   </Col>
                    <Col
                        span={7}
                        style={{textAlign:'right'}}
                    >
                        <span>手机号码</span>
                        <Input
                            style={{
                                marginLeft:10,
                                width:"72%"
                            }}
                            placeholder="  请输入电话号码"
                            value={phone}
                            onChange={e=>setPhone(e.target.value)}
                        />
                    </Col>
                
                    <Col
                        span={3}
                        style={{textAlign:'center'}}
                    >
                    <Tooltip title="查找">
                        <Button  style={{marginLeft:6}}  type="primary" shape="circle" icon={<SearchOutlined />} onClick={handleSearch}/>
                    </Tooltip>
                    <Tooltip title="重置">
                        <Button  style={{marginLeft:6}} shape="circle" icon={<RedoOutlined />} onClick={clear}/>
                    </Tooltip>
                    <Tooltip title="收起">
                        <Button style={{marginLeft:6}} shape="circle" icon={changeSearchState==true?<CaretUpOutlined/>:<CaretDownOutlined/>} onClick={flip_expandSearchPanel}/>
                    </Tooltip>
                    </Col>
                </Row>
                {
                    changeSearchState&&<Row
                            style={{marginTop:'10px'}}
                            justify = "end"
                            >
                            <Col
                            span={7}
                            style={{textAlign:'right'}}
                        >
                            <span style={{margin:'0 10px'}}>注册时间</span>
                            <RangePicker
                                style={{
                                    width:"72%"
                                }}
                                format={dateFormat}
                                value={time}
                                onChange={onChangeTime}
                            />
                            </Col>
                            <Col span={3}></Col>
                        </Row>
                        }
            </div>
            <div className={boxStyles.main}>
                <Table loading={loading}  onChange={tableChange} columns={columns} dataSource={dataSource} key='' rowKey={record=>record.id} pagination={Pagination}/>
            </div>
        </div>
    )
}
export default UserManage