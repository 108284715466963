import WtHostPost from '../util/WtHostPost';
import { Component } from 'react';
import React,{ useState,useEffect } from 'react';
import { FC, ReactElement } from 'react';
import WtDefaultBreadcrumb from "../custom/WtDefaultBreadcrumb";
import boxStyles from '../custom/boxStyles.module.less'
import { useHistory } from 'react-router-dom';
import type { ColumnsType } from 'antd/es/table';
import {logout} from '../util/WtLogout';
import { Input, Button, Tooltip, Row, Col, Space, Table ,Modal, Pagination,InputNumber} from 'antd'
import {CaretDownOutlined, CaretUpOutlined, RedoOutlined,FileTextOutlined, SearchOutlined,ExclamationCircleOutlined} from '@ant-design/icons';
import WtNotification from '../util/WtNotification';
const { confirm } = Modal;

interface Props {

}

interface DataType {
    id: number,
    name:string,
    sort:number
}

const WtAuthorityGroup:FC<Props> = (props): ReactElement => {
    const [name,setName]=useState('')
    const [sort,setSort]=useState(0)
    const history=useHistory()
    const [loading,setLoading]=useState(false)
    const [record,setRecord]=useState({} as any)
    const [isModalOpen,setIsModalOpen]=useState(false)
    const [AddType,setAddType]=useState(false)
    const [dataSource,setDataSource]=useState([])


    const columns: ColumnsType<DataType> = [
    {
        title: '权限组ID',
        dataIndex: 'id',
        key: 'id',
        width:"100px",
        align:'center',
    },
    {
        title: '权限组名称',
        dataIndex: 'name',
        align:'center',
        key: 'name',
    },
    {
      title: '操作',
      key: 'option',
      align:'center',
      render: (_, record:any) => {
        return <Space size="middle">
            <a onClick={()=>{changeGroup(record)}}>编辑</a>
            <a onClick={()=>{deleteGroup(record)}}>删除</a>
      </Space> 
    },
    },
];

    useEffect(()=>{
        getAuthorityGroupList()
    },[])

    const addGroup=()=>{
        setRecord({})
        setName('')
        setSort(0)
        setAddType(true)
        setIsModalOpen(true)
    }

    const changeGroup=(record)=>{
        setName(record.name)
        setRecord(record)
        setAddType(false)
        setIsModalOpen(true)
    }

    const deleteGroup=(record)=>{
        confirm({
            title: '删除权限组',
            icon: <ExclamationCircleOutlined />,
            content: '你确定要删除权限组吗?',
            okText: '删除',
            okType: 'danger',
            cancelText: '取消',
            onOk:()=>{
                WtHostPost('/admin/merchant/apigroup/del',{id:record.id},true).then(({json}: {json:any})=>{
                    console.log(json)
                    if(json.code===200){
                       WtNotification.success('删除成功')
                       setIsModalOpen(false)
                       getAuthorityGroupList()
                    }else if(json.code===422){
                        WtNotification.warning(json.msg)
                    }else if(json.code===401){
                        WtNotification.error('登录过期,请重新登录');
                        logout()
                    }else{
                        throw json;
                    }
                }).catch((error: any)=>{
                    WtNotification.error('系统出错,请联系管理员'+':'+error);
                })
            }
        })
    }

    const getAuthorityGroupList=()=>{
        setLoading(true)
        WtHostPost('/admin/merchant/apigroup/list',{},true,'get').then(({json}: {json:any})=>{
            console.log(json)
            if(json.code===200){
                setDataSource(json.data.apigroup)
                setLoading(false)
            }else if(json.code===422){
                setLoading(false)
                WtNotification.warning(json.msg)
            }else if(json.code===401){
                setLoading(false)
                WtNotification.error('登录过期,请重新登录');
                logout()
            }else{
                throw json;
            }
        }).catch((error: any)=>{
            WtNotification.error('系统出错,请联系管理员'+':'+error);
        })
    }

    const handleOk=()=>{
        if(AddType==true){
            let params={
                name:name,
                sort:sort
            }
            WtHostPost('/admin/merchant/apigroup/add',params,true).then(({json}: {json:any})=>{
                console.log(json)
                if(json.code===200){
                    WtNotification.success('添加成功')
                    getAuthorityGroupList()
                    setIsModalOpen(false)
                }else if(json.code===422){
                    WtNotification.warning(json.msg)
                }else if(json.code===401){
                    WtNotification.error('登录过期,请重新登录');
                    logout()
                }else{
                    throw json;
                }
            }).catch((error: any)=>{
                WtNotification.error('系统出错,请联系管理员'+':'+error);
            })
        }else{
            let params={
                id:record.id,
                name:name,
                sort:sort
            }
            WtHostPost('/admin/merchant/apigroup/update',params,true).then(({json}: {json:any})=>{
                console.log(json)
                if(json.code===200){
                    WtNotification.success('修改成功')
                    getAuthorityGroupList()
                    setIsModalOpen(false)
                }else if(json.code===422){
                    WtNotification.warning(json.msg)
                }else if(json.code===401){
                    WtNotification.error('登录过期,请重新登录');
                    logout()
                }else{
                    throw json;
                }
            }).catch((error: any)=>{
                WtNotification.error('系统出错,请联系管理员'+':'+error);
            })
        }
    }

    return (
        <div>
            <div className={boxStyles.head}>
                <WtDefaultBreadcrumb
                    secondLevel="权限管理"
                    thirdLevel='权限组管理'
                />
                    <h2>权限组管理</h2>
                    <p>当前设置权限组相关设置</p>
            </div>
      
            <div className={boxStyles.main}>
            <div style={{textAlign:'right',marginBottom:'10px'}}><Button onClick={()=>{addGroup()}} type="primary">新增</Button></div>
                <Table loading={loading}  columns={columns} dataSource={dataSource} key='' rowKey={record=>record.id} pagination={false}/>
            </div>

            <Modal title={AddType==true?'新增权限组':'编辑权限组'} open={isModalOpen} onOk={handleOk} onCancel={()=>{setIsModalOpen(false)}}>
                <div style={{display:'flex',alignItems:'center'}}>
                    <span style={{whiteSpace:'nowrap',width:'100px',textAlign:'right'}}>权限组名称:</span>
                    <Input
                        style={{
                            marginLeft:10,
                        }}
                        placeholder="  请输入权限组名称"
                        value={name}
                        onChange={e=>setName(e.target.value)}
                    />
                </div>
                <div style={{display:'flex',alignItems:'center',marginTop:'40px'}}>
                    <span style={{whiteSpace:'nowrap',width:'80px',textAlign:'right',marginRight:'10px'}}>排序:</span>
                    <InputNumber min={0} value={sort} onChange={(val:any)=>{setSort(val)}}/>
                </div>
            </Modal>
        </div>
    )
}
export default WtAuthorityGroup