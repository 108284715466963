import React from 'react';
import { SmileOutlined, CrownOutlined, TabletOutlined, AntDesignOutlined } from '@ant-design/icons';
import  { Component } from 'react';
export default {
  route: {
    // path: '/admin',
    routes: [
      {
        path: '/admin',
        name: '欢迎',
        icon: <SmileOutlined/>,
      },
      {
        path: '/user',
        name: '用户管理',
        icon: <CrownOutlined />,
        access: 'user',
        routes: [
          {
            path: '/user/userManage',
            name: '用户列表',
            icon: <CrownOutlined />,
            access: 'userManage',
          }
        ],
      },
      {
        path: '/merchant',
        name: '商户管理',
        icon: <CrownOutlined />,
        access: 'canAdmin',
        routes: [
          {
            path: '/merchant/merchantManage',
            name: '商户列表',
            icon: <CrownOutlined />,
            access: 'merchantManage',
          }
        ],
      },
      {
        path: '/option',
        name: '系统设置',
        icon: <CrownOutlined />,
        access: 'canAdmin',
        routes: [
          {
            path: '/option/accountManage',
            name: '账户管理',
            icon: <CrownOutlined />,
            access: 'accountManage',
          }
        ],
      },
      {
        path: '/product',
        name: '商品管理',
        icon: <CrownOutlined />,
        access: 'canAdmin',
        routes: [
          {
            path: '/product/assort',
            name: '分类管理',
            icon: <CrownOutlined />,
            access: 'accountManage',
          }
        ],
      },
      {
        path: '/authority',
        name: '权限管理',
        icon: <CrownOutlined />,
        access: 'authority',
        routes: [
          {
            path: '/authority/level',
            name: '等级管理',
            icon: <CrownOutlined />,
            access: 'accountManage',
          },
          {
            path: '/authority/menu',
            name: '菜单权限管理',
            icon: <CrownOutlined />,
            access: 'accountManage',
          },
          {
            path: '/authority/authority',
            name: '权限管理',
            icon: <CrownOutlined />,
            access: 'accountManage',
          },
          {
            path: '/authority/authorityGroup',
            name: '权限组管理',
            icon: <CrownOutlined />,
            access: 'accountManage',
          },
        ],
      },
      {
        path: '/company',
        name: '企业管理',
        icon: <CrownOutlined />,
        access: 'authority',
        routes: [
          {
            path: '/company/companyManage',
            name: '企业菜单权限管理',
            icon: <CrownOutlined />,
            access: 'accountManage',
          },
          {
            path: '/company/authority',
            name: '企业权限管理',
            icon: <CrownOutlined />,
            access: 'accountManage',
          },
          {
            path: '/company/authorityGroup',
            name: '企业权限组管理',
            icon: <CrownOutlined />,
            access: 'accountManage',
          }
        ],
      },
    ],
  },
  location: {
    pathname: '/',
  },
};