import WtHostPost from '../util/WtHostPost';
import { Component } from 'react';
import React,{ useState,useEffect } from 'react';
import { FC, ReactElement } from 'react';
import WtDefaultBreadcrumb from "../custom/WtDefaultBreadcrumb";
import boxStyles from '../custom/boxStyles.module.less'
import type { ColumnsType } from 'antd/es/table';
import {logout} from '../util/WtLogout';
import { Input, Button, InputNumber,Upload, Space, Table,Switch, Form,Badge,Modal,Radio } from 'antd'
import {LoadingOutlined,ExclamationCircleOutlined,PlusOutlined} from '@ant-design/icons';
import WtNotification from '../util/WtNotification';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import getBase64 from '../util/WtUpLoadImg'
import request from '../util/WtAxios'
const { confirm } = Modal;
interface Props {

}
interface DataType {
    id:number,
    name: string,
    path: string,
    hidden:number,
    title:string,
    children?: DataType[];
    component:String,
}


const WtMenusManage:FC<Props> = (props): ReactElement => {
    const [loading,setLoading]=useState(false)
    const [AddType,setAddType]=useState(true)
    const [menuModalOpen,setMenuModalOpen]=useState(false)
    const [form] = Form.useForm();
    const [record,setRecord]=useState({} as any)
    const [TitleImageUrl,setTitleImageUrl]=useState('')
    const [ImageUrl,setImageUrl]=useState('')
    const [dataSource,setDataSource]=useState([])


    const columns: ColumnsType<DataType> = [
    {
      title: '菜单标题',
      dataIndex: 'title',
      key: 'title',
      align:'center',
    },
    {
        title: '菜单名字',
        dataIndex: 'name',
        key: 'name',
        align:'center',
    },
    {
      title: '菜单路径',
      dataIndex: 'path',
      align:'center',
      key: 'path',

    },
    {
        title: '前端组件',
        dataIndex: 'component',
        align:'center',
        key: 'component',
    },

    {
        title: '状态',
        dataIndex: 'hidden',
        align:'center',
        key: 'hidden',
        render:(text,record)=>{
            if(text===0){
                return <Badge key='green' color='green' text='正常'/>
            }else{
                return <Badge key='gary' color='gray' text='隐藏'/>
            }
        }
    },
    {
      title: '操作',
      key: 'option',
      align:'center',
      render: (_, record:any) => {
        return <Space size="middle">
            <a onClick={()=>{addMenus(record)}}>新增子菜单</a>
            <a onClick={()=>{getMenusDetail(record)}}>编辑</a>
            <a onClick={()=>{changeUserStatus(record)}}>{record.hidden===0?'隐藏':'显示'}</a>
            <a onClick={()=>{deleteMenu(record)}}>删除</a>
        </Space>

    },
    },
];

    useEffect(()=>{
        getMenusList()
    },[])

    //递归删除所有没有子类的children属性
    const dealData=(data)=>{
        for(let i=0;i<data.length;i++){
            if(data[i].children.length===0){
                delete data[i].children
            }else{
                dealData(data[i].children)
            }
        }
        return data
    }
    //递归增加所有分类的等级
    const addLevel=(data,level)=>{
        if(level==3){
            return data
        }else{
            for(let i=0;i<data.length;i++){
                data[i].level=level
                if(data[i].children){
                    data[i].children=addLevel(data[i].children,level+1)
                }
            } 
            return data
        }
    }
    
    const addMenus=(record:any)=>{
        setRecord(record)
        setAddType(true)
        setMenuModalOpen(true)
        form.setFieldsValue({
            title:'',
            name:'',
            sort:0,
            path:'',
            component:'',
            hidden:0,
            icon:''
        })
    }

    const deleteMenu=(record)=>{
        confirm({
            title: '删除菜单',
            icon: <ExclamationCircleOutlined />,
            content:'您确定要删除该菜单吗?',
            onOk:()=>{
                WtHostPost('/admin/org/merchant/menus/del',{id:record.id},true).then(({json}: {json:any})=>{
                    if(json.code===200){
                        WtNotification.success('删除成功')
                        getMenusList()
                    }else if(json.code===422){
                        WtNotification.warning(json.msg)
                    }else if(json.code===401){
                        WtNotification.error('登录过期,请重新登录');
                        logout()
                    }else{
                        throw json;
                    }
                }).catch((error: any)=>{
                    WtNotification.error('系统出错,请联系管理员'+':'+error);
                })
            },
        });
    }

    const getMenusDetail=(record)=>{
        setAddType(false)
        setRecord(record)
        setMenuModalOpen(true)
        form.setFieldsValue({
            title:record.title,
            name:record.name,
            sort:record.sort,
            path:record.path,
            component:record.component,
            hidden:record.hidden,
            icon:record.icon
        })
    }

    const getMenusList=()=>{
        setLoading(true)
        WtHostPost('/admin/org/merchant/menus/list',{},true,'get').then(({json}: {json:any})=>{
            if(json.code===200){
                let dataSource=dealData(json.data.menus)
                dataSource=addLevel(dataSource,0)
                setDataSource(json.data.menus)
                setLoading(false)
            }else if(json.code===422){
                setLoading(false)
                WtNotification.warning(json.msg)
            }else if(json.code===401){
                WtNotification.error('登录过期,请重新登录');
                logout()
            }else{
                setLoading(false)
                throw json;
            }
        }).catch((error: any)=>{
            WtNotification.error('系统出错,请联系管理员'+':'+error);
        })
    }

    const changeUserStatus = (record:any)=>{
        confirm({
            title: '状态修改',
            icon: <ExclamationCircleOutlined />,
            content: record.status===0?'您确定要显示该菜单吗?':'您确定要隐藏该菜单吗?',
            onOk:()=>{
              changeStatus(record)
            },
          });
    }



    const onFinish=(val)=>{
        if(AddType===false){
            WtHostPost('/admin/org/merchant/menus/update',{
                id:record.id,
                parent_id:record.parent_id,
                path: val.path,
                name: val.name,
                hidden: val.hidden,
                component:val.component,
                sort: val.sort,
                title:val.title,
                icon: val.icon
            },true).then(({json}: {json:any})=>{
                if(json.code===200){
                    WtNotification.success('修改成功')
                    setMenuModalOpen(false)
                    getMenusList()
                }else if(json.code===422){
                    WtNotification.warning(json.msg)
                }else if(json.code===401){
                    WtNotification.error('登录过期,请重新登录');
                    logout()
                }else{
                    throw json;
                }
            }).catch((error:any)=>{
                WtNotification.error('系统出错,请联系管理员'+':'+error);
            })
        }else{
            console.log(record.parent_id)
            WtHostPost('/admin/org/merchant/menus/add',{
                    parent_id:record.id?record.id:0,
                    path: val.path,
                    name: val.name,
                    hidden: val.hidden,
                    component:val.component,
                    sort: val.sort,
                    title:val.title,
                    icon: val.icon
                },true).then(({json}: {json:any})=>{
                    if(json.code===200){
                        WtNotification.success('添加成功')
                        setMenuModalOpen(false)
                        getMenusList()
                    }else if(json.code===422){
                        WtNotification.warning(json.msg)
                    }else if(json.code===401){
                        WtNotification.error('登录过期,请重新登录');
                        logout()
                    }else{
                        throw json;
                    }
                }).catch((error:any)=>{
                    WtNotification.error('系统出错,请联系管理员'+':'+error);
                })
        }
    }


    const changeStatus=(record)=>{
        console.log(record)
        WtHostPost('/admin/org/merchant/menus/update',{
            ...record,
            hidden:record.hidden===0?1:0
        },true).then(({json}: {json:any})=>{
            if(json.code===200){
                WtNotification.success('修改成功')
                getMenusList()
            }else if(json.code===422){
                WtNotification.warning(json.msg)
            }else if(json.code===401){
                WtNotification.error('登录过期,请重新登录');
                logout()
            }else{
                throw json;
            }
        }).catch((error:any)=>{
            WtNotification.error('系统出错,请联系管理员'+':'+error);
        })
    }
    return (
        <div>
            <div className={boxStyles.head}>
                <WtDefaultBreadcrumb
                    secondLevel="权限管理"
                    thirdLevel='菜单管理'
                />
                    <h2>菜单管理</h2>
                    <p>当前设置菜单的层级关系</p>
            </div>
            <div className={boxStyles.main}>
                <div style={{textAlign:'right',marginBottom:'10px'}}><Button onClick={()=>{addMenus({})}} type="primary">新增</Button></div>
                <Table bordered loading={loading} columns={columns} dataSource={dataSource} key='' rowKey={record=>record.id} pagination={false}/>
            </div>

                    {/* 用户编辑新增弹窗 */}
            <Modal title={AddType==true?'新增分类':'编辑分类'} open={menuModalOpen} footer={false} onCancel={()=>{setMenuModalOpen(false)}}>
                <div>
                    <Form
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 20 }}
                        layout="horizontal"
                        form={form}
                        onFinish={onFinish}
                        initialValues={{
                            hidden:0,
                        }}
                    >
                        <Form.Item name="title" label="菜单标题" rules={[{ required: true }]}>
                            <Input placeholder='请输入菜单标题(title)'/>
                        </Form.Item>

                        <Form.Item name="name" label="菜单名称" rules={[{ required: true }]}>
                            <Input placeholder='请输入菜单名称(name)'/>
                        </Form.Item>
                         
                        <Form.Item name="path" label="菜单路径" rules={[{ required: true }]}>
                            <Input placeholder='请输入菜单路径'/>
                        </Form.Item>

                        <Form.Item name="component" label="前端组件" >
                            <Input placeholder='请输入前端组件路径'/>
                        </Form.Item>

                        <Form.Item name="icon" label="图标">
                            <Input placeholder='请输入图标组件名称'/>
                        </Form.Item>

                        <Form.Item name="sort" label="排序">
                            <InputNumber min={0}/>
                        </Form.Item>

                        <Form.Item name='hidden' label="状态">
                            <Radio.Group 
                                options={
                                    [
                                        { label: '显示', value: 0 },
                                        { label: '隐藏', value: 1 }
                                    ]
                                }
                                buttonStyle="solid"
                                optionType="button" />
                        </Form.Item>
        
                        <Form.Item style={{textAlign:'right',width:'100%'}}>
                            <Button type="primary" htmlType="submit">确定</Button>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
            {/* 分类编辑新增弹窗 */}

        </div>
    )
}
export default WtMenusManage