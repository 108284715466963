import ReactLoading from 'react-loading';
import styled from 'styled-components'
import React, { Component } from 'react';

const ReactLoading2 :any =ReactLoading;

const Container = styled.div`
    display: flex;
    justify-content: center;
`;

const LoadingBox = styled.div`
    text-align: center;
`;

const TitleBox = styled.span`
    font-size: 40px;
`;

//通用的过场组件
const loadingComponent =()=>{
    return (
        // <div>loading</div>
        <Container>
            <LoadingBox>
                <ReactLoading2 type={'bars'} color={'#1890ff'} height={375} width={375} />
                <TitleBox>Loading...</TitleBox>
            </LoadingBox>
        </Container>
    )
};

export default loadingComponent;