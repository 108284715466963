import {applyMiddleware,combineReducers,compose} from 'redux'
import {configureStore} from '@reduxjs/toolkit'
import rawCounterReducer from './rawCounterReducer';
import countReducer from './countReducer'
import authReducer from './authReducer';


// const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const composeEnhancer = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = configureStore({
    reducer:{
        rawCounter: rawCounterReducer,
        counter: countReducer,
        auth: authReducer,
    }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;