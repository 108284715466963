import {createSlice} from '@reduxjs/toolkit'

let initialState = {
  
};
let loginData=localStorage.getItem('userInfo')

if(loginData!==null){
    initialState={
        login:true,
        userInfo:loginData,
        try:true
    }
}else{
    initialState={
        login:false,
        userInfo:{},
        try:false
    }
}

export const counterSlice = createSlice({
    name: 'auth',
    initialState,
    reducers:{
        login: (state, action) => {
            const userInfo = action.payload;
            console.log('登录了')
			return {
				login:true,
				userInfo,
				try:true,
			};
        },
        logout: (state) => {
            return {
				login:false,
				userInfo:null,
				try:true,
			};
        },
        loginFail: () => {
            return {
				login:false,
				userInfo:null,
				try:true,
			};
        }
    }
});


export const {login, logout, loginFail} = counterSlice.actions;

export default counterSlice.reducer;