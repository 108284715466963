/**
 * Created by coder on 2018/5/14.
 */
import config from '../config'
import {logout} from './WtLogout'
const {tokenKey} = config;

export default {
    getToken():string|null {
        const token = localStorage.getItem(tokenKey);
        const tokenEx = JSON.parse(localStorage.getItem(tokenKey + 'Ex') || 'null');
        if (!token || !tokenEx) {
            //无效token
            return null;
        }

        if (tokenEx.remember) {
            return token;
        } else {
            if (tokenEx.timestamp - new Date().getTime() > 3600 * 2 * 1000) {
                //token 已经过期
                logout();
                return null;
            } else {
                return token;
            }
        }
    },

    setToken(token: string, remember?: boolean|undefined) {
        console.log('进来了')
        localStorage.setItem(tokenKey, token);

        if (remember === undefined) {
            const tokenEx = JSON.parse(localStorage.getItem(tokenKey + 'Ex') || 'null');
            remember = tokenEx ? tokenEx.remember : true;
        }

        localStorage.setItem(tokenKey + 'Ex', JSON.stringify({
            remember: remember,
            timestamp: new Date().getTime(),
        }));
    },

    removeToken() {
        localStorage.removeItem(tokenKey);
        localStorage.removeItem(tokenKey + 'Ex');
        localStorage.removeItem(tokenKey + 'Fresh');
    },

    isRefreshing(): boolean {
        return !!localStorage.getItem(tokenKey + 'Fresh');
    },


    setRefreshing(v: boolean) {
        if (v) {
            localStorage.setItem(tokenKey + 'Fresh', '1');
        } else {
            localStorage.removeItem(tokenKey + 'Fresh');
        }
    }
};