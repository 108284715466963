import WtHostPost from '../util/WtHostPost';
import { useState,useEffect } from 'react';
import React, { Component } from 'react';
import WtDefaultBreadcrumb from "../custom/WtDefaultBreadcrumb";
import boxStyles from '../custom/boxStyles.module.less'
import styles from '../user/userDetail.module.less';
import { useHistory,useParams } from 'react-router-dom';
import { Avatar,Descriptions,Badge,PageHeader,Spin } from 'antd';
import WtNotification from '../util/WtNotification';
import { logout } from '../util/WtLogout';

export default function WtMerchantDetail(){
    const histroy=useHistory()
    const urlParams=useParams() as any
    const [spinning,setSpinning]=useState(false)
    const [userInfo,setUserInfo]=useState({
        logo:'',
        created_at:'',
        id:'',
        title:'',
        mobile:'',
        company_name:'',
        address:'',
        status:0
    }) as any


   useEffect(()=>{
        getMerchantMessage()
   },[])

   const getMerchantMessage=()=>{
        setSpinning(true)
        WtHostPost(`/admin/merchant/detail/${urlParams.id}`,{},true,'get').then(({json}: {json:any})=>{
            console.log(json)
            if(json.code===200){
                setUserInfo(json.data)
                setSpinning(false)
            }else if(json.code===422){
                WtNotification.warning(json.msg)
                setSpinning(false)
            }else if(json.code===401){
                WtNotification.error('登录过期,请重新登录');
                setSpinning(false)
                logout()
            }else{
                throw json;
            }
        }).catch((error:any)=>{
            setSpinning(false)
            WtNotification.error('系统出错,请联系管理员'+':'+error);
        })
   }

   const switchStatus=(status)=>{
    if(status===1){
        return <Badge key='green' color='green' text='正常'/>
    }else if(status===10){
        return  <Badge key='#000' color='#000' text='小黑屋'/>
    }else if(status===20){
        return  <Badge key='gray' color='gary' text='申请注销'/>
    }else if(status===30){
        return  <Badge key='red' color='red' text='禁用'/>
    }else if(status===0){
        return <Badge key='pink' color='pink' text='关店'/>
    }
   }

    return (
        <div>
            <div className={boxStyles.main}>
                <PageHeader
                    className="site-page-header"
                    onBack={() =>{histroy.goBack()}}
                    title="返回"
                />
                <div className={styles.title} style={{marginTop:'0px'}}>
                    基本信息
                </div>
                <div className={styles.basis}>
                    <div className={styles.avatar} style={{alignSelf:'self-start'}}>
                        <img src={userInfo.logo==''?'https://thirdwx.qlogo.cn/mmopen/vi_32/POgEwh4mIHO4nibH0KlMECNjjGxQUq24ZEaGT4poC6icRiccVGKSyXwibcPq4BWmiaIGuG1icwxaQX6grC9VemZoJ8rg/132':userInfo.logo}/>
                    </div>
                    
                    <div className={styles.userMessage}>
                    <Spin tip="Loading..." spinning={spinning}>
                        <Descriptions size={'default'} column={2}>
                            <Descriptions.Item label="商户ID">{userInfo.id}</Descriptions.Item>
                            <Descriptions.Item label="商户名称">{userInfo.title}</Descriptions.Item>
                            <Descriptions.Item label="店铺等级">暂无</Descriptions.Item>
                            <Descriptions.Item label="联系人">暂无</Descriptions.Item>
                            <Descriptions.Item label="手机号码">{userInfo.mobile}</Descriptions.Item>
                            <Descriptions.Item label="店铺公司名称">{userInfo.company_name}</Descriptions.Item>
                            <Descriptions.Item label="地址">{userInfo.address}</Descriptions.Item>

                            <Descriptions.Item label="店铺状态">{switchStatus(userInfo.status)}</Descriptions.Item>
                            <Descriptions.Item label="入驻时间">{userInfo.created_at}</Descriptions.Item>
                            <Descriptions.Item label="商家营业时间">暂无</Descriptions.Item>
                            <Descriptions.Item label="店铺关闭原因">暂无</Descriptions.Item>
                        </Descriptions>
                    </Spin>
                    </div>
                </div>
            </div>
        </div>
    )
}