import WtHostPost from '../util/WtHostPost';
import { Component } from 'react';
import React,{ useState,useEffect } from 'react';
import { FC, ReactElement } from 'react';
import WtDefaultBreadcrumb from "../custom/WtDefaultBreadcrumb";
import boxStyles from '../custom/boxStyles.module.less'
import { useHistory } from 'react-router-dom';
import type { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import {logout} from '../util/WtLogout';
import { Input, Button, Tooltip, Row, Col,Select, Space, Table, Tag  ,DatePicker,Badge,Modal, Pagination } from 'antd'
import {CaretDownOutlined, CaretUpOutlined, RedoOutlined,FileTextOutlined, SearchOutlined,ExclamationCircleOutlined} from '@ant-design/icons';
import WtNotification from '../util/WtNotification';
import 'moment/locale/zh-cn';
const Option = Select.Option;
const { RangePicker } = DatePicker;
const { confirm } = Modal;
const dateFormat = 'YYYY-MM-DD';
interface Props {

}
interface DataType {
    id: string,
    nickname: string,
    mobile:string,
    level: string,
    status:string,
    source:string,
    created_at:string,
    avatar:string
}

type SearchType = {
    phone:string,
    title:string,
    company_name:string,
    startTime:string,
    endTime:string
}
const MerchantManage:FC<Props> = (props): ReactElement => {

    const [identity,setIdentity]=useState('')
    const [changeSearchState,setChangeSearchState]=useState<Boolean>(false)
    const [time,setTime]=useState() as any
    const [phone,setPhone]=useState('') as any
    const [title,setTitle]=useState('') as any
    const [company_name,setCompanyname]=useState('') as any
    const [startTime,setStartTime]=useState('') as any
    const [endTime,setendTime]=useState('') as any
    const history=useHistory()
    const [loading,setLoading]=useState(false)
    const tableChange=(pagination:any)=>{
        console.log(pagination)
        let pager = pagination;
        pager.current = pagination.current;
        pager.pageSize=pagination.pageSize
        setPagination({...pager})
    }
    const [searchData,setSearchData]=useState<SearchType>({phone:'',title:'',company_name:'',startTime:'',endTime:''})
    const [dataSource,setDataSource]=useState([])
    const [Pagination, setPagination] = useState({
        showQuickJumper:true,
        showSizeChanger:true,
        pageSize:10,
        current:1,
        pageSizeOptions: ['10', '20', '50', '100'],
        total:0
    });

    const columns: ColumnsType<DataType> = [
    {
      title: '商户ID',
      dataIndex: 'id',
      key: 'id',
      align:'center',
      render: (text) => <a>{text}</a>,
    },
    {
        title: '商户名称',
        dataIndex: 'title',
        align:'center',
        key: 'title',
    },
    {
      title: '商户头像',
      dataIndex: 'logo',
      align:'center',
      key: 'logo',
      render(value, record, index) {
        if(value===''){
            return <img style={{width:'50px',height:'50px',borderRadius:'50% 50%'}} src='https://thirdwx.qlogo.cn/mmopen/vi_32/POgEwh4mIHO4nibH0KlMECNjjGxQUq24ZEaGT4poC6icRiccVGKSyXwibcPq4BWmiaIGuG1icwxaQX6grC9VemZoJ8rg/132'/>
        }else{
            return <img style={{width:'50px',height:'50px',borderRadius:'50% 50%'}} src={value}/>
        }
          
      },
    },
    {
        title: '手机号码',
        dataIndex: 'mobile',
        align:'center',
        key: 'mobile',
    },
    {
        title: '店铺公司名称',
        dataIndex: 'company_name',
        align:'center',
        key: 'company_name',
    },
    {
        title: '地址',
        align:'center',
        dataIndex: 'address',
        key: 'address',
    },
    {
        title: '商户状态',
        dataIndex: 'status',
        align:'center',
        key: 'status',
        render(text:number,record:any){
            if(text===1){
                return <Badge key='green' color='green' text='正常'/>
            }else if(text===10){
                return  <Badge key='#000' color='#000' text='小黑屋'/>
            }else if(text===20){
                return  <Badge key='gray' color='gary' text='申请注销'/>
            }else if(text===30){
                return  <Badge key='red' color='red' text='禁用'/>
            }else if(text===0){
                return <Badge key='pink' color='pink' text='关店'/>
            }
        }
    },
    {
        title: '注册时间',
        dataIndex: 'created_at',
        align:'center',
        key: 'created_at',
    },
    {
      title: '操作',
      key: 'option',
      render: (_, record:any) => {
        if(record.status===1){
            return <Space size="middle">
                <a onClick={()=>{history.push(`/merchant/merchantDetail/${record.id}`)}}>详情</a>
                <a onClick={()=>{changeMerchantStatus(record)}}>禁用</a>
                <a onClick={()=>{upDateItem(record)}}>编辑</a>
              </Space>   
        }else if(record.status===30){
            return <Space size="middle">
                <a onClick={()=>{history.push(`/merchant/merchantDetail/${record.id}`)}}>详情</a>
                <a onClick={()=>{changeMerchantStatus(record)}}>启用</a>
                <a onClick={()=>{upDateItem(record)}}>编辑</a>
              </Space> 
        }else{
            return <Space size="middle">
                <a onClick={()=>{history.push(`/merchant/merchantDetail/${record.id}`)}}>详情</a>
                <a onClick={()=>{upDateItem(record)}}>编辑</a>
              </Space> 
        }
    },
    },
];

    useEffect(()=>{
        getMerchantList()
    },[Pagination,searchData])

    const getMerchantList=()=>{
        let params={
                page: Pagination.current,
                pageSize: Pagination.pageSize,
                company_name: searchData.company_name,
                title: searchData.title,
                mobile: searchData.phone,
                start: searchData.startTime,
                end: searchData.endTime
        }
        setLoading(true)
        WtHostPost('/admin/merchant/list',{...params},true).then(({json}: {json:any})=>{
            console.log(json)
            if(json.code===200){
                setDataSource(json.data.data)
                let newPag=Pagination
                newPag.total = json.data.total;
                setPagination(newPag)
                setLoading(false)
            }else if(json.code===422){
                WtNotification.warning(json.msg)
            }else if(json.code===401){
                WtNotification.error('登录过期,请重新登录');
                logout()
            }else{
                throw json;
            }
        }).catch((error: any)=>{
            WtNotification.error('系统出错,请联系管理员'+':'+error);
        })
    }

    const clear= ()=>{
        setSearchData({phone:'',title:'',company_name:'',startTime:'',endTime:''})
        setCompanyname('')
        setPhone('')
        setStartTime('')
        setendTime('')
        setTitle('')
        setTime(undefined)
    }

    const handleSearch=()=>{
        setSearchData({phone,title,company_name,startTime,endTime})
    }

    const upDateItem=(item)=>{
        history.push(`/merchant/WtAddOrUpdateMer/${item.id}`)
    }

    const flip_expandSearchPanel=()=>{
        setChangeSearchState(!changeSearchState)
    }

    const onChangeTime=(value:any,dateString:any)=>{
        setTime(value)
        setStartTime(dateString[0])
        setendTime(dateString[1])
    }
    const changeMerchantStatus = (record:any)=>{
        console.log(record)
        confirm({
            title: '状态修改',
            icon: <ExclamationCircleOutlined />,
            content: record.status===30?'您确定要启用该用户吗状态吗?':'您确定要禁用该用户吗状态吗?',
            onOk:()=>{
              changeStatus(record)
            },
          });
    }

    const goAddMerchant=()=>{
        history.push(`/merchant/WtAddOrUpdateMer/add`)
    }

    const changeStatus=(record)=>{
        WtHostPost('/admin/merchant/status',{
            id:record.id,
            status:record.status===1?30:1
        },true).then(({json}: {json:any})=>{
            if(json.code===200){
                WtNotification.success('修改成功')
                getMerchantList()
            }else if(json.code===422){
                WtNotification.warning(json.msg)
            }else if(json.code===401){
                WtNotification.error('登录过期,请重新登录');
                logout()
            }else{
                throw json;
            }
        }).catch((error:any)=>{
            WtNotification.error('系统出错,请联系管理员'+':'+error);
        })
    }
    return (
        <div>
            <div className={boxStyles.head}>
                <WtDefaultBreadcrumb
                    secondLevel="商户管理"
                    thirdLevel='商户列表'
                />
                    <h2>商户列表</h2>
                    <p>当前设置商户相关设置</p>
            </div>
            <div className={boxStyles.search}>
            <Row
                  
                    justify = "end"
                >
                <Col
                    span={7}
                    style={{textAlign:'right'}}
                >
                    <span style={{margin:'0 10px'}}>商户名称</span>
                        <Input
                           style={{
                               marginLeft:10,
                               width:"72%"
                           }}
                           placeholder="  请输入用户昵称"
                           value={title}
                           onChange={e=>setTitle(e.target.value)}
                       />
                    </Col>
                <Col
                        span={7}
                       style={{textAlign:'right'}}
                   >
                       <span>店铺公司名称</span>
                       <Input
                           style={{
                               marginLeft:10,
                               width:"65%"
                           }}
                           placeholder="  请输入用户昵称"
                           value={company_name}
                           onChange={e=>setCompanyname(e.target.value)}
                       />
                   </Col>
                    <Col
                        span={7}
                        style={{textAlign:'right'}}
                    >
                        <span>手机号码</span>
                        <Input
                            style={{
                                marginLeft:10,
                                width:"72%"
                            }}
                            placeholder="  请输入电话号码"
                            value={phone}
                            onChange={e=>setPhone(e.target.value)}
                        />
                    </Col>
                
                    <Col
                        span={3}
                        style={{textAlign:'center'}}
                    >
                    <Tooltip title="查找">
                        <Button  style={{marginLeft:6}}  type="primary" shape="circle" icon={<SearchOutlined />} onClick={handleSearch}/>
                    </Tooltip>
                    <Tooltip title="重置">
                        <Button  style={{marginLeft:6}} shape="circle" icon={<RedoOutlined />} onClick={clear}/>
                    </Tooltip>
                    <Tooltip title="收起">
                        <Button style={{marginLeft:6}} shape="circle" icon={changeSearchState==true?<CaretUpOutlined/>:<CaretDownOutlined/>} onClick={flip_expandSearchPanel}/>
                    </Tooltip>
                    </Col>
                </Row>
                {
                    changeSearchState&&<Row
                            style={{marginTop:'10px'}}
                            justify = "end"
                            >
                            <Col
                            span={7}
                            style={{textAlign:'right'}}
                        >
                            <span style={{margin:'0 10px'}}>注册时间</span>
                            <RangePicker
                                style={{
                                    width:"72%"
                                }}
                                
                                format={dateFormat}
                                value={time}
                                onChange={onChangeTime}

                            />
                            </Col>
                            <Col span={3}></Col>
                        </Row>
                        }
            </div>
            <div className={boxStyles.main}>
                <div style={{float:'right'}}>
                    <Button type="primary" onClick={goAddMerchant}>新增商户</Button>
                </div>
                <Table loading={loading}  onChange={tableChange} columns={columns} dataSource={dataSource} key='' rowKey={record=>record.id} pagination={Pagination}/>
            </div>
        </div>
    )
}
export default MerchantManage