import React from 'react'
import {
    HashRouter,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import { connect, ConnectedProps } from 'react-redux'
import WtUnauthorizedLayout from '../layout/WtUnauthorizedLayout'
import WtAntProLayout from '../layout/WtAntProLayout';
import {login,loginFail} from '../store/authReducer'
import WtUserInfoLoading from '../auth/WtUserInfoLoading'

interface Props extends PropsFromRedux {

}

class WtRoute extends React.PureComponent<Props> {
    componentDidMount(){
        console.log('componentDidMount');
    }

    authCheck = (props: any) => {
        const {auth} = this.props;
        if (auth.login) {
            return <WtAntProLayout {...props}/>;;
        } else if (auth.try === false) {
            return <WtUserInfoLoading/>
        } else {
            return <Redirect to="/ua/login"/>;
        }
    };

    uaCheck = (props: any) => {
        console.info('uaCheck');
        const {auth} = this.props;
        console.log(auth)
        if (auth.login) {
            return <Redirect to='/admin'/>;
        } else if (auth.try === false) {
            return <WtUserInfoLoading/>
        } else {
            return <WtUnauthorizedLayout {...props}/>;
        }
    };

    adminCheck = (props: any) => {
        console.log('adminCheck');
        const {auth} = this.props;
        if (auth.login) {
            return <WtAntProLayout {...props}/>;
        } else if (auth.try === false) {
            return <WtUserInfoLoading/>
        } else {
            return <Redirect to="/ua/login"/>;
        }
    };

    render() {
        console.log('render')
        return (
            <HashRouter>
                <Switch>
                    <Route path='/admin' component={this.adminCheck}/>
                    <Route path='/ua' component={this.uaCheck}/>
                    <Route path='/' component={this.authCheck}/>
                </Switch>
            </HashRouter>
        );
    }
}


const mapStateToProps = (state: any) => {
    return { auth: state.auth }
}


const mapDispatchToProps = {
    login,
    loginFail,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

const connector = connect<StateProps, DispatchProps>(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(WtRoute) as any;
