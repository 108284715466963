import WtHostPost from '../util/WtHostPost';
import { Component } from 'react';
import React,{ useState,useEffect } from 'react';
import { FC, ReactElement } from 'react';
import WtDefaultBreadcrumb from "../custom/WtDefaultBreadcrumb";
import boxStyles from '../custom/boxStyles.module.less'
import { useHistory,useParams } from 'react-router-dom';
import type { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import {logout} from '../util/WtLogout';
import {Upload, TimePicker ,Input, Button, Tooltip, Row, Col,Select, Space, Table, Tag  ,DatePicker,Badge,Modal, Pagination,PageHeader,Form } from 'antd'
import {PlusOutlined,CaretDownOutlined, CaretUpOutlined, RedoOutlined,FileTextOutlined, SearchOutlined,ExclamationCircleOutlined,LoadingOutlined} from '@ant-design/icons';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import request from '../util/WtAxios'
import WtNotification from '../util/WtNotification';
import 'moment/locale/zh-cn';

const Option = Select.Option;
const { RangePicker } = DatePicker;
const { confirm } = Modal;
const dateFormat = 'YYYY-MM-DD';
interface Props {

}

const WtAddOrUpdateMer:FC<Props> = (props): ReactElement => {
    const [form] = Form.useForm();
    const history=useHistory()
    const urlParams=useParams() as any
    const [levelList,setLevelList]=useState([]) as any
    const [ImageUrl,setImageUrl]=useState('')
    const [AvatarUrl,setAvatarUrl]=useState('')
    const [loading,setLoading]=useState(false)
    useEffect(()=>{
        if(urlParams.id!='add'){
            getDetailData()
        }
        getLevelList()
    },[])
    const getDetailData=()=>{
        WtHostPost(`/admin/merchant/detail/${urlParams.id}`,{},true,'get','/api/v1').then(({json}: {json:any})=>{
            if(json.code===200){
                setAvatarUrl(json.data.logo)
                setImageUrl(json.data.logo)
               form.setFieldsValue({
                    title:json.data.title,
                    mobile:json.data.mobile,
                    logo:json.data.logo,
                    company_name:json.data.company_name,
                    address:json.data.address,
                    level_id:json.data.level_id==0?'':json.data.level_id,
                    business_time:[moment(json.data.business_time.split('--')[0],'HH:mm:ss'),moment(json.data.business_time.split('--')[1],'HH:mm:ss')]
               })
            }else if(json.code===422){
                WtNotification.warning(json.msg)
            }else if(json.code===401){
                WtNotification.error('登录过期,请重新登录');
                logout()
            }else{
                throw json;
            }
        }).catch((error: any)=>{
            WtNotification.error('系统出错,请联系管理员'+':'+error);
        })
    }
    const getLevelList=()=>{
        WtHostPost('/admin/merchant/level/list',{},true,'get').then(({json}: {json:any})=>{
            if(json.code===200){
                let data=json.data.list.map(ele=>{
                    return {
                        value:ele.id,
                        label:ele.name,
                    }
                })
                setLevelList(data)
            }else if(json.code===422){
                WtNotification.warning(json.msg)
            }else if(json.code===401){
                WtNotification.error('登录过期,请重新登录');
                logout()
            }else{
                throw json;
            }
        }).catch((error: any)=>{
            WtNotification.error('系统出错,请联系管理员'+':'+error);
        })
    }

    const getBase64 = (img: RcFile, callback: (url: string) => void) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result as string));
        reader.readAsDataURL(img);
    };

    const beforeUpload = (file: RcFile) => {
        getBase64(file,(url)=>{
            setImageUrl(url)
            uploadImage(file)
        })
    };
    const uploadButton = (
        <div>
          {loading ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>上传</div>
        </div>
      );

      const uploadImage=(file: RcFile)=>{
        let token=localStorage.getItem('userToken')
        let formData = new window.FormData(); 
        formData.append('file',file);
        request({
            url:'/api/1/v1/common/file/upload',
            method:'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data',
                'authorization': 'Bearer'+ ' '+token
            },
            data:formData
        }).then((response) => {
           if(response.data.code===200){
                let params={
                    parent_id:0,
                    repository_identity:response.data.data.identity,
                    ext:response.data.data.ext,
                    name:response.data.data.name,
                }
                WtHostPost('/common/repository/save',params,true,'post','/api/1/v1').then(({json}: {json:any})=>{
                    if(json.code===200){
                        setAvatarUrl(json.data.url)
                    }else if(json.code===422){
                        WtNotification.warning(json.msg)
                    }else if(json.code===401){
                        WtNotification.error('登录过期,请重新登录');
                        logout()
                    }else{
                        throw json;
                    }
                })
           }
        })
    }

    const onFinish=(val)=>{
        if(urlParams.id=='add'){
            let params={
                "status": 1,
                "cate_id": 0,
                "level_id": val.level_id,
                "address":val.address,
                "title": val.title,
                "mobile":val.mobile,
                "latitude":0,
                "longitude":0,
                "company_name":val.company_name,
                "close_info": "",
                "logo":AvatarUrl,
                "business_time": moment(val.business_time[0]).format('hh:mm:ss')+'--'+moment(val.business_time[1]).format('hh:mm:ss'),
                "wait": 0,
                "surplus": 0,
                "used": 0.00,
                "public_key": "",
                "private_key": "",
                "now_volume": 0,
                "total_volume":0,
                "geohash": ""
            }
            console.log(params)
            WtHostPost('/admin/merchant/add',params,true,'post','/api/v1').then(({json}: {json:any})=>{
                if(json.code===200){
                    WtNotification.success('添加成功')
                    history.push('/merchant/merchantManage')
                }else if(json.code===422){
                    WtNotification.warning(json.msg)
                }else if(json.code===401){
                    WtNotification.error('登录过期,请重新登录');
                    logout()
                }else{
                    throw json;
                }
            })
        }else{
            let params={
                id:Number(urlParams.id),
                "status": 1,
                "cate_id": 0,
                "level_id": val.level_id,
                "address":val.address,
                "title": val.title,
                "mobile":val.mobile,
                "latitude":0,
                "longitude":0,
                "company_name":val.company_name,
                "close_info": "",
                "logo":AvatarUrl,
                "business_time": moment(val.business_time[0]).format('hh:mm:ss')+'--'+moment(val.business_time[1]).format('hh:mm:ss'),
                "wait": 0,
                "surplus": 0,
                "used": 0.00,
                "public_key": "",
                "private_key": "",
                "now_volume": 0,
                "total_volume":0,
                "geohash": ""
            }
            console.log(params)
            WtHostPost('/admin/merchant/edit',params,true,'post','/api/v1').then(({json}: {json:any})=>{
                if(json.code===200){
                    WtNotification.success('修改成功')
                    history.push('/merchant/merchantManage')
                }else if(json.code===422){
                    WtNotification.warning(json.msg)
                }else if(json.code===401){
                    WtNotification.error('登录过期,请重新登录');
                    logout()
                }else{
                    throw json;
                }
            })
        }
        
        
    }
    return (
        <div className={boxStyles.main}>
            <PageHeader
                className="site-page-header"
                onBack={() =>{history.goBack()}}
                title="返回"
            />
        <h3>新增商户</h3>
        <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            form={form}
            autoComplete="off"
            >
            <Form.Item
                label="商户名称"
                name="title"
                rules={[{ required: true, message: '请输入商户名称' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item 
                name="logo" 
                label="头像"
            >
                <Upload
                    name="avatar"
                    listType="picture-card"
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    fileList={[] as any}
                    >
                    {ImageUrl ? <img src={ImageUrl} alt="avatar" style={{ width: '100%',height:"100%" }} /> : uploadButton}
                </Upload>
            </Form.Item>
            <Form.Item
                label="店铺公司名称"
                name="company_name"
                rules={[{ required: true, message: '请输入店铺名称' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="店铺等级"
                name="level_id"
                rules={[{ required: true, message: '请选择店铺等级' }]}
            >
                  <Select
                        placeholder='请选择店铺等级'
                        options={levelList}
                    />
            </Form.Item>
            <Form.Item
                label="商户地址"
                name="address"
                rules={[{ required: true, message: '请输入商户地址' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="手机号"
                name="mobile"
                rules={[{ required: true, message: '请输入手机号' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="营业时间"
                name="business_time"
                rules={[{ required: true, message: '请输入营业时间' }]}
            >
                <TimePicker.RangePicker />
            </Form.Item>


            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                    确定
                </Button>
            </Form.Item>
            </Form>
        </div>
    )
}
export default WtAddOrUpdateMer