import axios from 'axios'


const service = axios.create({
  // baseURL: '',
  timeout: 5000
})

service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

// service.interceptors.response.use(
//   response => {
//     return {json:response.data,header:response.headers,status:response.status};
//   },
//   error => {
//     return Promise.reject(error)
//   }
// )

export default service