import WtHostPost from '../util/WtHostPost';
import { Component } from 'react';
import React,{ useState,useEffect} from 'react';
import { FC, ReactElement } from 'react';
import WtDefaultBreadcrumb from "../custom/WtDefaultBreadcrumb";
import boxStyles from '../custom/boxStyles.module.less'
import { useHistory, useParams } from 'react-router-dom';
import type { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import {logout} from '../util/WtLogout';
import { Input, Button,Upload,Tooltip,Spin,Row, Form,Col,Select, Space, Table, Tag  ,DatePicker,Badge,Modal, Pagination,Descriptions, Avatar} from 'antd'
import {CaretDownOutlined,PlusOutlined,InfoCircleOutlined,LoadingOutlined,CaretUpOutlined, RedoOutlined,FileTextOutlined, SearchOutlined,ExclamationCircleOutlined} from '@ant-design/icons';
import WtNotification from '../util/WtNotification';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import request from '../util/WtAxios'
import 'moment/locale/zh-cn';
const Option = Select.Option;
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { confirm } = Modal;
const dateFormat = 'YYYY-MM-DD';
interface Props {

}
interface DataType {
    id: string,
    username: string,
    phone:string,
    status:string,
    nick_name:string,
    created_at:string,
    avatar:string
}

type SearchType = {
    phone:string,
    username:string,
    nickname:string,
    startTime:string,
    endTime:string
}
const AccountManage:FC<Props> = (props): ReactElement => {
    const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>('checkbox');
    const [identity,setIdentity]=useState('')
    const [form] = Form.useForm();
    const [accountDetailData,setAccountDetail]=useState({}) as any
    const [changeSearchState,setChangeSearchState]=useState<Boolean>(false)
    const [time,setTime]=useState() as any
    const [phone,setPhone]=useState('') as any
    const [username,setUsername]=useState('') as any
    const [nickname,setNickname]=useState('') as any
    const [startTime,setStartTime]=useState('') as any
    const [endTime,setendTime]=useState('') as any
    const history=useHistory()
    const [isPassWordModalOpen, setPassWordModalOpen] = useState(false);
    const [isDetailModalOpen, setDetailModalOpen] = useState(false);
    const [isAccountModalOpen,setIsAccountModalOpen]=useState(false)
    const [loading,setLoading]=useState(false)
    const [addType,setAddType]=useState(false)
    const [passWord,setPassWord]=useState('')
    const [selectedRows,setSelectedRows]=useState([]) as any
    const [recordId,setRecordId]=useState(0)
    const [ImageUrl,setImageUrl]=useState('')
    const [spin,setSpin]=useState(false)
    const [AvatarUrl,setAvatarUrl]=useState('')
    const tableChange=(pagination:any)=>{
        let pager = pagination;
        pager.current = pagination.current;
        pager.pageSize=pagination.pageSize
        setPagination({...pager})
    }
    const [searchData,setSearchData]=useState<SearchType>({phone:'',username:'',nickname:'',startTime:'',endTime:''})
    const [dataSource,setDataSource]=useState([])
    const [Pagination, setPagination] = useState({
        showQuickJumper:true,
        showSizeChanger:true,
        pageSize:10,
        current:1,
        pageSizeOptions: ['10', '20', '50', '100'],
        total:0
    });

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
            setSelectedRows(selectedRows)
        }
    };

    const columns: ColumnsType<DataType> = [
    {
      title: '用户账号',
      dataIndex: 'username',
      key: 'username',
      align:'center',
    },
    {
      title: '用户头像',
      dataIndex: 'avatar',
      align:'center',
      key: 'avatar',
      render(value, record, index) {
        if(value===''){
            return <img style={{width:'50px',height:'50px',borderRadius:'50% 50%'}} src='https://thirdwx.qlogo.cn/mmopen/vi_32/POgEwh4mIHO4nibH0KlMECNjjGxQUq24ZEaGT4poC6icRiccVGKSyXwibcPq4BWmiaIGuG1icwxaQX6grC9VemZoJ8rg/132'/>
        }else{
            return <img style={{width:'50px',height:'50px',borderRadius:'50% 50%'}} src={value}/>
        }
          
      },
    },
    {
        title: '用户昵称',
        dataIndex: 'nick_name',
        align:'center',
        key: 'nick_name',
    },
    {
        title: '手机号码',
        dataIndex: 'phone',
        align:'center',
        key: 'phone',
    },
    {
        title: '账号状态',
        dataIndex: 'status',
        align:'center',
        key: 'status',
        render(text:number,record:any){
            if(text===1){
                return <Badge key='green' color='green' text='正常'/>
            }else if(text===7){
                return  <Badge key='#000' color='#000' text='小黑屋'/>
            }else if(text===5){
                return  <Badge key='gray' color='gary' text='申请注销'/>
            }else if(text===0){
                return  <Badge key='red' color='red' text='禁用'/>
            }
        }
    },
    {
        title: '创建时间',
        dataIndex: 'created_at',
        align:'center',
        key: 'created_at',
    },
    {
      title: '操作',
      key: 'option',
      align:'center',
      render: (_, record:any) => {
        if(record.status===1){
            return <Space size="middle">
                <a onClick={()=>{accountUpdate(record)}}>编辑</a>
                <a onClick={()=>{accountDetail(record)}}>详情</a>
                <a onClick={()=>{changeAccountStatus(record)}}>禁用</a>
                <a onClick={()=>{changePassWord(record)}}>重置密码</a>
              </Space>   
        }else if(record.status===0){
            return <Space size="middle">
                <a onClick={()=>{accountUpdate(record)}}>编辑</a>
                <a onClick={()=>{accountDetail(record)}}>详情</a>
                <a onClick={()=>{changeAccountStatus(record)}}>启用</a>
                <a onClick={()=>{changePassWord(record)}}>重置密码</a>
              </Space> 
        }else{
            return <Space size="middle">
                <a onClick={()=>{accountUpdate(record)}}>编辑</a>
                <a onClick={()=>{accountDetail(record)}}>详情</a>
                <a onClick={()=>{changePassWord(record)}}>重置密码</a>
              </Space> 
        }
    },
    },
];

    useEffect(()=>{
        getAccountList()
    },[Pagination,searchData])

    const accountDetail=(record:any)=>{
        setDetailModalOpen(true)
        setSpin(true)
        WtHostPost(`/admin/detail/${record.id}`,{},true,'get').then(({json}: {json:any})=>{
            if(json.code===200){
                setAccountDetail(json.data)
                setSpin(false)
            }else if(json.code===422){
                WtNotification.warning(json.msg)
                setSpin(false)
                setDetailModalOpen(false)
            }else if(json.code===401){
                WtNotification.error('登录过期,请重新登录');
                setSpin(false)
                setDetailModalOpen(false)
                logout()
            }else{
                throw json;
            }
        }).catch((error: any)=>{
            setSpin(false)
            setDetailModalOpen(false)
            WtNotification.error('系统出错,请联系管理员'+':'+error);
        })
    }

    const accountUpdate=(record:any)=>{
        setRecordId(record.id)
        setIsAccountModalOpen(true)
        WtHostPost(`/admin/detail/${record.id}`,{},true,'get').then(({json}: {json:any})=>{
            if(json.code===200){
                console.log(json)
                form.setFieldsValue({
                    username:json.data.username,
                    nickname:json.data.nickname,
                    phone: json.data.phone,
                    avatar: json.data.avatar,
                    gender: json.data.gender,
                    email: json.data.email,
                    status: json.data.status,
                    remark:json.data.remark
                })
                setImageUrl(json.data.avatar)
                setAvatarUrl(json.data.avatar)

            }else if(json.code===422){
                WtNotification.warning(json.msg)
            }else if(json.code===401){
                WtNotification.error('登录过期,请重新登录');
                logout()
            }else{
                throw json;
            }
        }).catch((error: any)=>{
            WtNotification.error('系统出错,请联系管理员'+':'+error);
        })
    }

    const addAccount=()=>{
        form.setFieldsValue({
            username:'',
            nickname:'',
            phone:'',
            avatar:'',
            gender:0,
            email:'',
            status:1,
            remark:''
        })
        setIsAccountModalOpen(true)
        setAddType(true)
    }

    const getAccountList=()=>{
        let params={
                page: Pagination.current,
                pageSize: Pagination.pageSize,
                nickname: searchData.nickname,
                username: searchData.username,
                phone: searchData.phone,
                start: searchData.startTime,
                end: searchData.endTime
            }

      
        setLoading(true)
        WtHostPost('/admin/list',{...params},true).then(({json}: {json:any})=>{
            if(json.code===200){
                setDataSource(json.data.data)
                let newPag=Pagination
                newPag.total = json.data.total;
                setPagination(newPag)
                setLoading(false)
            }else if(json.code===422){
                WtNotification.warning(json.msg)
            }else if(json.code===401){
                WtNotification.error('登录过期,请重新登录');
                logout()
            }else{
                throw json;
            }
        }).catch((error: any)=>{
            WtNotification.error('系统出错,请联系管理员'+':'+error);
        })
    }

    const clear= ()=>{
        setSearchData({phone:'',username:'',nickname:'',startTime:'',endTime:''})
        setNickname('')
        setPhone('')
        setStartTime('')
        setendTime('')
        setUsername('')
        setTime(undefined)
        let newPag=Pagination
        newPag.current=1
        setPagination(newPag)
    }

    const changePassWord=(record)=>{
        setRecordId(record.id)
        setPassWordModalOpen(true)
    }

    const handleSearch=()=>{
        setSearchData({phone,username,nickname,startTime,endTime})
        let newPag=Pagination
        newPag.current=1
        setPagination(newPag)
    }

    const flip_expandSearchPanel=()=>{
        setChangeSearchState(!changeSearchState)
    }

    const onChangeTime=(value:any,dateString:any)=>{
        setTime(value)
        setStartTime(dateString[0])
        setendTime(dateString[1])
    }
    const changeAccountStatus = (record:any)=>{
        confirm({
            title: '状态修改',
            icon: <ExclamationCircleOutlined />,
            content: record.status===0?'您确定要启用该用户吗状态吗?':'您确定要禁用该用户吗状态吗?',
            onOk:()=>{
              changeStatus(record)
            },
          });
    }

    const changeStatus=(record)=>{
        WtHostPost('/admin/status',{
            ids:[record.id],
            status:record.status===1?0:1
        },true).then(({json}: {json:any})=>{
            if(json.code===200){
                WtNotification.success('修改成功')
                getAccountList()
            }else if(json.code===422){
                WtNotification.warning(json.msg)
            }else if(json.code===401){
                WtNotification.error('登录过期,请重新登录');
                logout()
            }else{
                throw json;
            }
        }).catch((error:any)=>{
            WtNotification.error('系统出错,请联系管理员'+':'+error);
        })
    }

    const batchChangeStatus=()=>{
        if(selectedRows.length===0){
            WtNotification.warning('请先选择需要禁用的账户')
            return
        }

        let ids=selectedRows.map(ele=>ele.id)
        confirm({
            title: '状态修改',
            icon: <ExclamationCircleOutlined />,
            content:'您确定要禁用这些账户的状态吗?',
            onOk:()=>{
                WtHostPost('/admin/status',{
                    ids:ids,
                    status:0
                },true).then(({json}: {json:any})=>{
                    if(json.code===200){
                        WtNotification.success('修改成功')
                        getAccountList()
                    }else if(json.code===422){
                        WtNotification.warning(json.msg)
                    }else if(json.code===401){
                        WtNotification.error('登录过期,请重新登录');
                        logout()
                    }else{
                        throw json;
                    }
                }).catch((error:any)=>{
                    WtNotification.error('系统出错,请联系管理员'+':'+error);
                })
            },
          });
    }

    const changePassWordSend=()=>{
        if(passWord===''){
            WtNotification.warning('请先输入重置密码')
            return
        }
        WtHostPost('/admin/reset_pwd',{
            id:recordId,
            password:passWord
        },true).then(({json}: {json:any})=>{
            if(json.code===200){
                WtNotification.success('修改成功')
                setPassWordModalOpen(false)
            }else if(json.code===422){
                WtNotification.warning(json.msg)
            }else if(json.code===401){
                WtNotification.error('登录过期,请重新登录');
                logout()
            }else{
                throw json;
            }
        }).catch((error:any)=>{
            WtNotification.error('系统出错,请联系管理员'+':'+error);
        })
    }

    const onFinish = (values: any) => {
        console.log(values);
        let params
        if(addType===true){
            params={
                username:values.username,
                nickname:values.nickname,
                phone: values.phone,
                password:values.password,
                avatar: AvatarUrl,
                role_id: values.role_id,
                dept_id: values.dept_id,
                gender: values.gender,
                email: values.email,
                status: values.status,
                remark:values.remark
            }
        }else{
            params={
                id:recordId,
                username:values.username,
                nickname:values.nickname,
                phone: values.phone,
                avatar: AvatarUrl,
                role_id: values.role_id,
                dept_id: values.dept_id,
                gender: values.gender,
                email: values.email,
                status: values.status,
                remark:values.remark
            }
        }
        
        WtHostPost('/admin/add',params,true).then(({json}: {json:any})=>{
            if(json.code===200){
                console.log(json)
                WtNotification.success(addType===true?'添加成功':'修改成功')
                setIsAccountModalOpen(false)
                getAccountList()
            }else if(json.code===422){
                WtNotification.warning(json.msg)
            }else if(json.code===401){
                WtNotification.error('登录过期,请重新登录');
                logout()
            }else{
                throw json;
            }
        }).catch((error:any)=>{
            WtNotification.error('系统出错,请联系管理员'+':'+error);
        })
    };

    const getBase64 = (img: RcFile, callback: (url: string) => void) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result as string));
        reader.readAsDataURL(img);
    };

    const beforeUpload = (file: RcFile) => {
        getBase64(file,(url)=>{
            setImageUrl(url)
            uploadImage(file)
        })
    };

    const uploadImage=(file: RcFile)=>{
        let token=localStorage.getItem('userToken')
        let formData = new window.FormData(); 
        formData.append('file',file);
        request({
            url:'/api/1/v1/common/file/upload',
            method:'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data',
                'authorization': 'Bearer'+ ' '+token
            },
            data:formData
        }).then((response) => {
           if(response.data.code===200){
                let params={
                    parent_id:0,
                    repository_identity:response.data.data.identity,
                    ext:response.data.data.ext,
                    name:response.data.data.name,
                }
                WtHostPost('/common/repository/save',params,true,'post','/api/1/v1').then(({json}: {json:any})=>{
                    if(json.code===200){
                        setAvatarUrl(json.data.url)
                    }else if(json.code===422){
                        WtNotification.warning(json.msg)
                    }else if(json.code===401){
                        WtNotification.error('登录过期,请重新登录');
                        logout()
                    }else{
                        throw json;
                    }
                })
           }
        })
    }

    const uploadButton = (
        <div>
          {loading ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>上传</div>
        </div>
      );
      
    return (
        <div>
            <div className={boxStyles.head}>
                <WtDefaultBreadcrumb
                    secondLevel="系统设置"
                    thirdLevel='账户管理'
                />
                    <h2>账户管理</h2>
                    <p>当前设置账户相关设置</p>
            </div>
            <div className={boxStyles.search}>
            <Row
                  
                    justify = "end"
                >
                <Col
                    span={7}
                    style={{textAlign:'right'}}
                >
                    <span style={{margin:'0 10px'}}>用户账号</span>
                        <Input
                           style={{
                               marginLeft:10,
                               width:"72%"
                           }}
                           placeholder="  请输入用户昵称"
                           value={username}
                           onChange={e=>setUsername(e.target.value)}
                       />
                    </Col>
                <Col
                        span={7}
                       style={{textAlign:'right'}}
                   >
                       <span>用户昵称</span>
                       <Input
                           style={{
                               marginLeft:10,
                               width:"72%"
                           }}
                           placeholder="  请输入用户昵称"
                           value={nickname}
                           onChange={e=>setNickname(e.target.value)}
                       />
                   </Col>
                    <Col
                        span={7}
                        style={{textAlign:'right'}}
                    >
                        <span>手机号码</span>
                        <Input
                            style={{
                                marginLeft:10,
                                width:"72%"
                            }}
                            placeholder="  请输入电话号码"
                            value={phone}
                            onChange={e=>setPhone(e.target.value)}
                        />
                    </Col>
                
                    <Col
                        span={3}
                        style={{textAlign:'center'}}
                    >
                    <Tooltip title="查找">
                        <Button  style={{marginLeft:6}}  type="primary" shape="circle" icon={<SearchOutlined />} onClick={handleSearch}/>
                    </Tooltip>
                    <Tooltip title="重置">
                        <Button  style={{marginLeft:6}} shape="circle" icon={<RedoOutlined />} onClick={clear}/>
                    </Tooltip>
                    <Tooltip title="收起">
                        <Button style={{marginLeft:6}} shape="circle" icon={changeSearchState==true?<CaretUpOutlined/>:<CaretDownOutlined/>} onClick={flip_expandSearchPanel}/>
                    </Tooltip>
                    </Col>
                </Row>
                {
                    changeSearchState&&<Row
                            style={{marginTop:'10px'}}
                            justify = "end"
                            >
                            <Col
                            span={7}
                            style={{textAlign:'right'}}
                        >
                            <span style={{margin:'0 10px'}}>注册时间</span>
                            <RangePicker
                                style={{
                                    width:"72%"
                                }}
                                
                                format={dateFormat}
                                value={time}
                                onChange={onChangeTime}

                            />
                            </Col>
                            <Col span={3}></Col>
                        </Row>
                        }
            </div>
            <div className={boxStyles.main}>
                <div style={{display:'flex',justifyContent:'space-between',marginBottom:"20px"}}>
                    <div>
                        <Button onClick={batchChangeStatus}>禁用</Button>
                    </div>
                    <div>
                        <Button type="primary" onClick={addAccount}>新增</Button>
                    </div>
                </div>

                <Table  
                    bordered 
                    loading={loading}  
                    onChange={tableChange} 
                    columns={columns} 
                    dataSource={dataSource} 
                    key='' 
                    rowKey={record=>record.id} 
                    pagination={Pagination}
                    rowSelection={{
                        type: selectionType,
                        ...rowSelection,
                      }}
                />
            </div>

            {/* 修改密码弹窗 */}
            <Modal title="" open={isPassWordModalOpen} onOk={changePassWordSend} closable={false} onCancel={()=>{setPassWordModalOpen(false)}}>
                <div style={{display:'flex',alignItems:'center',marginBottom:'30px'}}>
                    <InfoCircleOutlined style={{color:'#faad14',fontSize:'26px'}} />
                    <div style={{color:'#000000d9',fontWeight:'bold',fontSize:'16px',lineHeight:'1.4',marginLeft:'10px'}}>你确定要修改密码吗?</div>
                </div>
                <div style={{display:'flex',alignItems:'center'}}>
                    <span style={{whiteSpace:'nowrap'}}>修改密码</span>
                    <Input.Password
                        style={{
                            marginLeft:10,
                        }}
                        
                        placeholder="  请输入重置密码"
                        value={passWord}
                        onChange={e=>setPassWord(e.target.value)}
                    />
                </div>
            </Modal>
            {/* 修改密码弹窗 */}

            {/* 详情弹窗 */}
            <Modal title="" open={isDetailModalOpen} footer={false} width={800} onCancel={()=>{setDetailModalOpen(false)}}>
                <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                <Spin tip="Loading..." spinning={spin}>
                    <Descriptions title="用户详情" bordered column={2} style={{width:'100%'}}> 
                        <Descriptions.Item label="用户头像" contentStyle={{textAlign:'center'}} >
                            {accountDetailData.avatar===''?
                            <img style={{width:'50px',height:'50px',borderRadius:'50% 50%'}} src='https://thirdwx.qlogo.cn/mmopen/vi_32/POgEwh4mIHO4nibH0KlMECNjjGxQUq24ZEaGT4poC6icRiccVGKSyXwibcPq4BWmiaIGuG1icwxaQX6grC9VemZoJ8rg/132'/>
                            :<img  style={{width:'50px',height:'50px',borderRadius:'50% 50%'}} src={accountDetailData.avatar}/>
                        }
                        </Descriptions.Item>
                        <Descriptions.Item  contentStyle={{textAlign:'center'}} label="用户昵称">{accountDetailData.nickname}</Descriptions.Item>
                        <Descriptions.Item  contentStyle={{textAlign:'center'}} label="用户账号">{accountDetailData.username}</Descriptions.Item>
                        <Descriptions.Item  contentStyle={{textAlign:'center'}} label="手机号码">{accountDetailData.phone}</Descriptions.Item>
                        <Descriptions.Item  contentStyle={{textAlign:'center'}} label="用户角色">{accountDetailData.role_id==0?'':''}</Descriptions.Item>
                        <Descriptions.Item  contentStyle={{textAlign:'center'}} label="用户部门">{accountDetailData.dept_id==0?'':''}</Descriptions.Item>
                        <Descriptions.Item  contentStyle={{textAlign:'center'}} label="用户性别">{accountDetailData.gender===0?'男':'女'}</Descriptions.Item>
                        <Descriptions.Item  contentStyle={{textAlign:'center'}} label="邮箱">{accountDetailData.email}</Descriptions.Item>
                        <Descriptions.Item  contentStyle={{textAlign:'center'}} label="备注">{accountDetailData.remark}</Descriptions.Item>
                    </Descriptions>
                </Spin>
                </div>
            </Modal>
            {/* 详情弹窗 */}


            {/* 用户编辑新增弹窗 */}
            <Modal title={addType==true?'新增用户':'编辑用户'} open={isAccountModalOpen} footer={false} onCancel={()=>{setIsAccountModalOpen(false)}}>
                <div>
                    <Form
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 20 }}
                        layout="horizontal"
                        form={form}
                        onFinish={onFinish}
                    >
                        <Form.Item name="avatar" label="头像">
                            <Upload
                                name="avatar"
                                listType="picture-card"
                                showUploadList={false}
                                beforeUpload={beforeUpload}
                                fileList={[] as any}
                                >
                                {ImageUrl ? <img src={ImageUrl} alt="avatar" style={{ width: '100%',height:"100%" }} /> : uploadButton}
                            </Upload>
                        </Form.Item>

                        <Form.Item name="nickname" label="用户昵称">
                            <Input placeholder='请输入用户昵称'/>
                        </Form.Item>
                         
                        <Form.Item name="phone" label="手机号码" rules={[{ required: true }]}>
                            <Input  placeholder='请输入手机号'/>
                        </Form.Item>

                             
                        <Form.Item name="username" label="用户账号" rules={[{ required: true }]}>
                            <Input  placeholder='请输入用户账号'/>
                        </Form.Item>

                        {
                            addType==true&&<Form.Item name="password" label="登录密码" rules={[{ required: true }]}>
                            <Input.Password  placeholder='请输入登录密码'/>
                        </Form.Item>
                        }
                    

                        <Form.Item name='role_id' label="用户角色">
                            <Select disabled ></Select>
                        </Form.Item>

                        <Form.Item name='dept_id' label="用户部门">
                            <Select disabled ></Select>
                        </Form.Item>

                        <Form.Item name='gender' label="用户性别">
                            <Select  placeholder='请选择用户性别'>
                                <Select.Option value={0} >男</Select.Option>
                                <Select.Option value={1} >女</Select.Option>
                            </Select>
                        </Form.Item>

                        <Form.Item name="email" label="邮箱">
                            <Input  placeholder='请输入邮箱'/>
                        </Form.Item>

                        <Form.Item name='status' label="状态" rules={[{ required: true }]}>
                            <Select>
                                <Select.Option value={0} >禁用</Select.Option>
                                <Select.Option value={1} >启用</Select.Option>
                            </Select>
                        </Form.Item>
        
                        <Form.Item name='remark' label="备注">
                            <TextArea rows={4} placeholder="请输入备注"/>
                        </Form.Item>
        
                        <Form.Item style={{textAlign:'right',width:'100%'}}>
                            <Button type="primary" htmlType="submit">确定</Button>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
            {/* 用户编辑新增弹窗 */}


        </div>
    )
}
export default AccountManage