import { Switch, Route } from "react-router-dom";
import React, { Component } from 'react';
import WtLogin from '../auth/WtLogin'
import WtAdminHome from '../admin/WtAdminHome';
import WtUserManage from '../user/userManage'
import WtUserDetail from '../user/userDetail'
const WtUnauthorizedLayout = () => {
    return (
        <Switch>
            <Route path='/ua/login'><WtLogin/></Route>
            <Route path='/admin' exact><WtAdminHome/></Route>
            <Route path='/user/userManage' exact><WtUserManage/></Route>
            <Route path='/user/userDetail/:id' exact><WtUserDetail/></Route>
            {/* <Route path='*'>404</Route> */}
        </Switch>
		
    );
}


export default WtUnauthorizedLayout;