import WtHostPost from '../util/WtHostPost';
import type { DataNode } from 'antd/es/tree';
import { Component } from 'react';
import React,{ useState,useEffect } from 'react';
import { FC, ReactElement } from 'react';
import WtDefaultBreadcrumb from "../custom/WtDefaultBreadcrumb";
import boxStyles from '../custom/boxStyles.module.less'
import {logout} from '../util/WtLogout';
import type { ColumnsType } from 'antd/es/table';
import { Input, Button, InputNumber,Upload, Space, Divider ,Table,Switch, Form,Badge,Modal,Select,Tree,Checkbox,} from 'antd'
import {LoadingOutlined,ExclamationCircleOutlined,PlusOutlined} from '@ant-design/icons';
import WtNotification from '../util/WtNotification';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import getBase64 from '../util/WtUpLoadImg'
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import request from '../util/WtAxios'
const CheckboxGroup = Checkbox.Group;
const { confirm } = Modal;
interface Props {

}
interface DataType {
    id: string,
    name: string,
    created:string
}

const WtUserLevelManage:FC<Props> = (props): ReactElement => {
    const [loading,setLoading]=useState(false)
    const [AddType,setAddType]=useState(true)
    const [authorityModalOpen,setAuthorityModalOpen]=useState(false)
    const [menuModalOpen,setMenuModalOpen]=useState(false)
    const [groupModalOpen,setGroupModalOpen]=useState(false)
    const [form] = Form.useForm();
    const [record,setRecord]=useState({} as any)

    const [checkedList, setCheckedList] = useState([] as any);
    const [indeterminate, setIndeterminate] = useState([] as any);
    const [checkAll, setCheckAll] = useState([] as any);
    const [apiGroup,setApiGroup]=useState([] as any)
    
    const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
    const [checkedKeys, setCheckedKeys] = useState<React.Key[]>([]);
    const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
    const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true);
    const [treeData,setTreeData]=useState<DataNode[]>([])
  
    const [dataSource,setDataSource]=useState([])

    const [apiGroup1,setApiGroup1]=useState([] as any)
    const [checkAll1, setCheckAll1] = useState([] as any);
    const [checkedList1, setCheckedList1] = useState([] as any);
    const [checkedKeys1, setCheckedKeys1] = useState<React.Key[]>([]);
    const [treeData1,setTreeData1]=useState<DataNode[]>([])
    const [menuModalOpen1,setMenuModalOpen1]=useState(false)
    const [expandedKeys1, setExpandedKeys1] = useState<React.Key[]>([]);
    const [autoExpandParent1, setAutoExpandParent1] = useState<boolean>(true);
    const [selectedKeys1, setSelectedKeys1] = useState<React.Key[]>([]);
    const [groupModalOpen1,setGroupModalOpen1]=useState(false)
    const [indeterminate1, setIndeterminate1] = useState([] as any);
    const columns: ColumnsType<DataType> = [
    {
        title: 'id',
        dataIndex: 'id',
        key: 'id',
        align:'center',
    },
    {
      title: '等级名称',
      dataIndex: 'name',
      key: 'name',
      align:'center',
    },
    {
        title: '创建时间',
        dataIndex: 'created_at',
        key: 'created_at',
        align:'center',
    },
    {
      title: '更新时间',
      dataIndex: 'updated_at',
      key: 'updated_at',
      align:'center',
  },
    {
      title: '操作',
      key: 'option',
      align:'center',
      render: (_, record:any) => {
        return <Space size="middle">
           <a onClick={()=>{upDateAuthority(record)}}>编辑</a>
           <a onClick={()=>{upDateGroup(record)}}>绑定用户权限</a>
           <a onClick={()=>{upDateMenu(record)}}>绑定菜单权限</a>
           <a onClick={()=>{upDateCompanyGroup(record)}}>绑定企业权限</a>
           <a onClick={()=>{upDateCompanyMenu(record)}}>绑定企业菜单权限</a>
           <a onClick={()=>{deleteLevel(record)}}>删除</a>
        </Space>   
    },
    },
];

    useEffect(()=>{
        getLevelList()
    },[])

    useEffect(()=>{
      if(apiGroup.length!==0){
        getMyChosedAuthorityData(record.id)
      }
    },[apiGroup])

    useEffect(()=>{
      if(apiGroup1.length!==0){
        getMyChosedAuthorityData1(record.id)
      }
    },[apiGroup1])

    const addAuthority=(record)=>{
        form.setFieldValue('name','')
        setAddType(true)
        setAuthorityModalOpen(true)
    }

    const upDateAuthority=(record)=>{
        setRecord(record)
        setAddType(false)
        form.setFieldValue('name',record.name)
        setAuthorityModalOpen(true)
    }

    const upDateGroup=(record)=>{
        setRecord(record)
        getAuthorityData(record.id)
    }
    const upDateCompanyGroup=(record)=>{
      setRecord(record)
      getCompanyAuthorityData(record.id)
    }

    const setDefaultCheckedList=(data)=>{
      let defultData=apiGroup.map(ele=>[])
      let ids=apiGroup.map(ele=>{
        return ele.apis.map(item=>item.id)
      })
      ids.map((ele,index)=>{
        data.map(item=>{
          if(ele.indexOf(item)!==-1){
            defultData[index].push(item)

          }
        })
      })
      defultData.map((ele,index)=>{
        onChange(ele,index)
      })
    }
    const setDefaultCheckedList1=(data)=>{
      let defultData=apiGroup1.map(ele=>[])
      let ids=apiGroup1.map(ele=>{
        return ele.apis.map(item=>item.id)
      })
      ids.map((ele,index)=>{
        data.map(item=>{
          if(ele.indexOf(item)!==-1){
            defultData[index].push(item)

          }
        })
      })
      defultData.map((ele,index)=>{
        onChange1(ele,index)
      })
    }

    const getMyChosedAuthorityData=(id)=>{
      WtHostPost(`/admin/merchant/level/apis?id=${id}`,{},true,'get').then(({json}: {json:any})=>{
        if(json.code===200){
          setGroupModalOpen(true)
          setDefaultCheckedList1(json.data.apis)
          // setCheckedList(json.data.apis)
        }else if(json.code===422){
            WtNotification.warning(json.msg)
        }else if(json.code===401){
            WtNotification.error('登录过期,请重新登录');
            logout()
        }else{
            throw json;
        }
    }).catch((error: any)=>{
        WtNotification.error('系统出错,请联系管理员'+':'+error);
    })
    }

    const getMyChosedAuthorityData1=(id)=>{
      WtHostPost(`/admin/org/merchant/level/apis?id=${id}`,{},true,'get').then(({json}: {json:any})=>{
        if(json.code===200){
          setGroupModalOpen1(true)
          setDefaultCheckedList1(json.data.apis)
          // setCheckedList(json.data.apis)
        }else if(json.code===422){
            WtNotification.warning(json.msg)
        }else if(json.code===401){
            WtNotification.error('登录过期,请重新登录');
            logout()
        }else{
            throw json;
        }
    }).catch((error: any)=>{
        WtNotification.error('系统出错,请联系管理员'+':'+error);
    })
    }
    const getCompanyAuthorityData=(id)=>{
      WtHostPost('/admin/org/merchant/apis/list',{},true,'get').then(({json}: {json:any})=>{
        if(json.code===200){
          setApiGroup1(json.data.apigroup)
          setCheckAll1(json.data.apigroup.map(ele=>false))
          setCheckedList1(json.data.apigroup.map(ele=>[]))
          console.log(apiGroup)
        }else if(json.code===422){
            WtNotification.warning(json.msg)
        }else if(json.code===401){
            WtNotification.error('登录过期,请重新登录');
            logout()
        }else{
            throw json;
        }
      }).catch((error: any)=>{
          WtNotification.error('系统出错,请联系管理员'+':'+error);
      })
    }

    const getAuthorityData=(id)=>{
      WtHostPost('/admin/merchant/apis/list',{},true,'get').then(({json}: {json:any})=>{
        if(json.code===200){
          setApiGroup(json.data.apigroup)
          setCheckAll(json.data.apigroup.map(ele=>false))
          setCheckedList(json.data.apigroup.map(ele=>[]))
          console.log(apiGroup)
        }else if(json.code===422){
            WtNotification.warning(json.msg)
        }else if(json.code===401){
            WtNotification.error('登录过期,请重新登录');
            logout()
        }else{
            throw json;
        }
    }).catch((error: any)=>{
        WtNotification.error('系统出错,请联系管理员'+':'+error);
    })
    }

    const upDateMenu=(record)=>{
        setRecord(record)
        getMyMenuChosedData(record.id)
        getMenusData()
    }
    const upDateCompanyMenu=(record)=>{
      setRecord(record)
      getMyMenuChosedData1(record.id)
      getMenusData1()
    }
    const dealChildren=(data:any)=>{
      let newData=data.map(ele=>{
        if(ele.children.length===0){
          return {
            title:ele.title,
            key:ele.id,
          }
        }else{
          return {
            title:ele.title,
            key:ele.id,
            children:dealChildren(ele.children)
          }
        }
    
      })
      return newData
    }

    const getMenusData1=()=>{
      WtHostPost('/admin/org/merchant/menus/list',{},true,'get').then(({json}: {json:any})=>{
        if(json.code===200){
            let newTreeData=dealChildren(json.data.menus)
            console.log(newTreeData)
            setTreeData1(newTreeData)
            setMenuModalOpen1(true)
        }else if(json.code===422){
            WtNotification.warning(json.msg)
        }else if(json.code===401){
            WtNotification.error('登录过期,请重新登录');
            logout()
        }else{
            throw json;
        }
      }).catch((error: any)=>{
          WtNotification.error('系统出错,请联系管理员'+':'+error);
      })
    }

    const getMenusData=()=>{
      WtHostPost('/admin/merchant/menus/list',{},true,'get').then(({json}: {json:any})=>{
        if(json.code===200){
            let newTreeData=dealChildren(json.data.menus)
            console.log(newTreeData)
            setTreeData(newTreeData)
            setMenuModalOpen(true)
        }else if(json.code===422){
            WtNotification.warning(json.msg)
        }else if(json.code===401){
            WtNotification.error('登录过期,请重新登录');
            logout()
        }else{
            throw json;
        }
      }).catch((error: any)=>{
          WtNotification.error('系统出错,请联系管理员'+':'+error);
      })
    }

    const menusSend=()=>{
      WtHostPost('/admin/merchant/level/menus',{
        id:record.id,
        menus:checkedKeys
      },true).then(({json}: {json:any})=>{
        if(json.code===200){
            WtNotification.success('绑定成功')
            setMenuModalOpen(false)
        }else if(json.code===422){
            WtNotification.warning(json.msg)
        }else if(json.code===401){
            WtNotification.error('登录过期,请重新登录');
            logout()
        }else{
            throw json;
        }
      }).catch((error: any)=>{
          WtNotification.error('系统出错,请联系管理员'+':'+error);
      })
    }

    const menusSend1=()=>{
      WtHostPost('/admin/org/merchant/level/menus',{
        id:record.id,
        menus:checkedKeys1
      },true).then(({json}: {json:any})=>{
        if(json.code===200){
            WtNotification.success('绑定成功')
            setMenuModalOpen1(false)
        }else if(json.code===422){
            WtNotification.warning(json.msg)
        }else if(json.code===401){
            WtNotification.error('登录过期,请重新登录');
            logout()
        }else{
            throw json;
        }
      }).catch((error: any)=>{
          WtNotification.error('系统出错,请联系管理员'+':'+error);
      })
    }

    const getMyMenuChosedData=(id)=>{
      WtHostPost(`/admin/merchant/level/menus?id=${id}`,{},true,'get').then(({json}: {json:any})=>{
        if(json.code===200){
            setCheckedKeys(json.data.menus)
            // setCheckedKeys([44])
        }else if(json.code===422){
            WtNotification.warning(json.msg)
        }else if(json.code===401){
            WtNotification.error('登录过期,请重新登录');
            logout()
        }else{
            throw json;
        }
      }).catch((error: any)=>{
          WtNotification.error('系统出错,请联系管理员'+':'+error);
      })
    }

    const getMyMenuChosedData1=(id)=>{
      WtHostPost(`/admin/org/merchant/level/menus?id=${id}`,{},true,'get').then(({json}: {json:any})=>{
        if(json.code===200){
            setCheckedKeys1(json.data.menus)
            // setCheckedKeys([44])
        }else if(json.code===422){
            WtNotification.warning(json.msg)
        }else if(json.code===401){
            WtNotification.error('登录过期,请重新登录');
            logout()
        }else{
            throw json;
        }
      }).catch((error: any)=>{
          WtNotification.error('系统出错,请联系管理员'+':'+error);
      })
    }

    const getLevelList=()=>{
      setLoading(true)
      WtHostPost('/admin/merchant/level/list',{},true,'get').then(({json}: {json:any})=>{
        if(json.code===200){
          setDataSource(json.data.list)
          setLoading(false)
        }else if(json.code===422){
            setLoading(false)
            WtNotification.warning(json.msg)
        }else if(json.code===401){
            WtNotification.error('登录过期,请重新登录');
            logout()
        }else{
            setLoading(false)
            throw json;
        }
      }).catch((error: any)=>{
        WtNotification.error('系统出错,请联系管理员'+':'+error);
      })
    }
    const deleteLevel=(record)=>{
        confirm({
            title: '删除状态',
            icon: <ExclamationCircleOutlined />,
            content: '您确定要删除该等级吗?',
            onOk:()=>{
              WtHostPost('/admin/merchant/level/del',{id:record.id},true).then(({json}: {json:any})=>{
                if(json.code===200){
                  getLevelList()
                  WtNotification.success('删除成功')
                  setAuthorityModalOpen(false)
                }else if(json.code===422){
                    WtNotification.warning(json.msg)
                }else if(json.code===401){
                    WtNotification.error('登录过期,请重新登录');
                    logout()
                }else{
                    throw json;
                }
              }).catch((error: any)=>{
                WtNotification.error('系统出错,请联系管理员'+':'+error);
              })
            },
          });
    }

    const onChange = (list: CheckboxValueType[],index) => {
        let newCheckedList=checkedList
        newCheckedList[index]=list
        setCheckedList(newCheckedList.map(ele=>(ele)));
        

        let newCheckAll=checkAll
        if(list.length!==0&&list.length==apiGroup[index].apis.length){
          newCheckAll[index]=true
        }else{
          newCheckAll[index]=false
        }
        setCheckAll(newCheckAll.map(ele=>(ele)))
        

        let newIndeterminate=indeterminate
        newIndeterminate[index]=!!list.length && list.length < apiGroup[index].apis.length
        setIndeterminate(newIndeterminate.map(ele=>ele));

      };
      
    const onChange1 = (list: CheckboxValueType[],index) => {
      let newCheckedList=checkedList1
      newCheckedList[index]=list
      setCheckedList1(newCheckedList.map(ele=>(ele)));
      
      let newCheckAll=checkAll1
      if(list.length!==0&&list.length==apiGroup1[index].apis.length){
        newCheckAll[index]=true
      }else{
        newCheckAll[index]=false
      }
      setCheckAll1(newCheckAll.map(ele=>(ele)))
      
      let newIndeterminate=indeterminate1
      newIndeterminate[index]=!!list.length && list.length < apiGroup1[index].apis.length
      setIndeterminate1(newIndeterminate.map(ele=>ele));

    };

    const onCheckAllChange1=(e: CheckboxChangeEvent,index) => {
      console.log(e,index)
      let checkValue=checkedList1 
        if(e.target.checked===true){
          let newCheckedList=apiGroup1[index].apis.map((ele)=>{
            return ele.id
          })
        checkValue[index]=newCheckedList
        setCheckedList1(checkValue.map(ele=>ele))
      }else{
        checkValue[index]=[]
        setCheckedList1(checkValue.map(ele=>ele))
      }
      
        let newIndeterminate=indeterminate1
        newIndeterminate[index]=false
        setIndeterminate(newIndeterminate.map(ele=>ele));

        let newCheckAll=checkAll1
        newCheckAll[index]=e.target.checked
        setCheckAll1(newCheckAll.map(ele=>ele));

        console.log(checkedList1)
    };

    const onCheckAllChange = (e: CheckboxChangeEvent,index) => {
      console.log(e,index)
      let checkValue=checkedList 
        if(e.target.checked===true){
          let newCheckedList=apiGroup[index].apis.map((ele)=>{
            return ele.id
          })
        checkValue[index]=newCheckedList
        setCheckedList(checkValue.map(ele=>ele))
      }else{
        checkValue[index]=[]
        setCheckedList(checkValue.map(ele=>ele))
      }
      
        let newIndeterminate=indeterminate
        newIndeterminate[index]=false
        setIndeterminate(newIndeterminate.map(ele=>ele));

        let newCheckAll=checkAll
        newCheckAll[index]=e.target.checked
        setCheckAll(newCheckAll.map(ele=>ele));

        console.log(checkedList)


    };

    const onFinish=(val)=>{
      if(AddType==false){
        let params={
          name:val.name,
          id:record.id
        }
        WtHostPost('/admin/merchant/level/update',params,true).then(({json}: {json:any})=>{
          if(json.code===200){
            getLevelList()
            WtNotification.success('修改成功')
            setAuthorityModalOpen(false)
          }else if(json.code===422){
              WtNotification.warning(json.msg)
          }else if(json.code===401){
              WtNotification.error('登录过期,请重新登录');
              logout()
          }else{
              throw json;
          }
        }).catch((error: any)=>{
          WtNotification.error('系统出错,请联系管理员'+':'+error);
        })
      }else{
        let params={
          name:val.name,
        }
        WtHostPost('/admin/merchant/level/add',params,true).then(({json}: {json:any})=>{
          if(json.code===200){
            getLevelList()
            WtNotification.success('添加成功')
            setAuthorityModalOpen(false)
          }else if(json.code===422){
              WtNotification.warning(json.msg)
          }else if(json.code===401){
              WtNotification.error('登录过期,请重新登录');
              logout()
          }else{
              throw json;
          }
        }).catch((error: any)=>{
          WtNotification.error('系统出错,请联系管理员'+':'+error);
        })
      }
       
        
    }

    const onExpand = (expandedKeysValue: React.Key[]) => {
        setExpandedKeys(expandedKeysValue);
        setAutoExpandParent(false);
      };
    const onExpand1 = (expandedKeysValue: React.Key[]) => {
      setExpandedKeys1(expandedKeysValue);
      setAutoExpandParent1(false);
    };
    
      const onCheck = (checkedKeysValue: any) => {
        // console.log(checkedKeysValue)
        setCheckedKeys(checkedKeysValue.checked);
      };
      const onCheck1 = (checkedKeysValue: any) => {
        // console.log(checkedKeysValue)
        setCheckedKeys1(checkedKeysValue.checked);
      };
    
      const onSelect = (selectedKeysValue: React.Key[], info: any) => {
        setSelectedKeys(selectedKeysValue);
      };
      const onSelect1 = (selectedKeysValue: React.Key[], info: any) => {
        setSelectedKeys1(selectedKeysValue);
      };

    const sendAuthority=()=>{
      let apis=[] as any
      checkedList.map(ele=>{
        if(ele.length!=0){
          ele.map((item:any)=>{
            apis.push(item)
          })
        }
      })
      WtHostPost(`/admin/merchant/level/apis`,{id:record.id,apis},true).then(({json}: {json:any})=>{
        if(json.code===200){
          WtNotification.success('添加权限成功')
          setGroupModalOpen(false)
        }else if(json.code===422){
            WtNotification.warning(json.msg)
        }else if(json.code===401){
            WtNotification.error('登录过期,请重新登录');
            logout()
        }else{
            throw json;
        }
    }).catch((error: any)=>{
        WtNotification.error('系统出错,请联系管理员'+':'+error);
    })
    }

    const sendAuthority1=()=>{
      let apis=[] as any
      checkedList1.map(ele=>{
        if(ele.length!=0){
          ele.map((item:any)=>{
            apis.push(item)
          })
        }
      })
      WtHostPost(`/admin/org/merchant/level/apis`,{id:record.id,apis},true).then(({json}: {json:any})=>{
        if(json.code===200){
          WtNotification.success('添加权限成功')
          setGroupModalOpen1(false)
        }else if(json.code===422){
            WtNotification.warning(json.msg)
        }else if(json.code===401){
            WtNotification.error('登录过期,请重新登录');
            logout()
        }else{
            throw json;
        }
    }).catch((error: any)=>{
        WtNotification.error('系统出错,请联系管理员'+':'+error);
    })
    }

    return (
        <div>
            <div className={boxStyles.head}>
                <WtDefaultBreadcrumb
                    secondLevel="权限管理"
                    thirdLevel='等级管理'
                />
                    <h2>等级管理</h2>
                    <p>当前页面需要将等级关联菜单权限和权限组,然后把等级分配给用户,间接管理商户的菜单权限和用户权限</p>
            </div>
            <div className={boxStyles.main}>
                <div style={{textAlign:'right',marginBottom:'10px'}}><Button onClick={()=>{addAuthority({})}} type="primary">新增</Button></div>
                <Table bordered loading={loading} columns={columns} dataSource={dataSource} key='' rowKey={record=>record.id} pagination={false}/>
            </div>

                    {/* 用户编辑新增弹窗 */}
            <Modal title={AddType==true?'新增等级':'编辑等级信息'} open={authorityModalOpen} footer={false} onCancel={()=>{setAuthorityModalOpen(false)}}>
                <div>
                <Form
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 20 }}
                        layout="horizontal"
                        form={form}
                        onFinish={onFinish}
                    >
                        <Form.Item name="name" label="等级名称" rules={[{ required: true }]}>
                            <Input  placeholder='请输入等级名称'/>
                        </Form.Item>
                        <div style={{textAlign:'right'}}>
                            <Button type="primary" htmlType="submit">确定</Button>
                        </div>
                       
                </Form>
                
                </div>
            </Modal>
            {/* 等级编辑新增弹窗 */}

             {/* 企业菜单权限弹窗弹窗 */}
             <Modal title='绑定菜单权限'open={menuModalOpen1} footer={false} onCancel={()=>{setMenuModalOpen1(false)}}>
                <div>
                    <Tree
                        checkable
                        onExpand={onExpand1}
                        expandedKeys={expandedKeys1}
                        autoExpandParent={autoExpandParent1}
                        onCheck={onCheck1}
                        checkedKeys={checkedKeys1}
                        onSelect={onSelect1}
                        selectedKeys={selectedKeys1}
                        treeData={treeData1}
                        checkStrictly={true}
                    />
                    <div style={{marginTop:'20px',textAlign:'right'}}>
                        <Button type="primary" onClick={menusSend1} htmlType="submit">确定</Button>
                    </div>
                     
                </div>
            </Modal>

            {/* 菜单权限弹窗弹窗 */}
            <Modal title='绑定菜单权限'open={menuModalOpen} footer={false} onCancel={()=>{setMenuModalOpen(false)}}>
                <div>
                    <Tree
                        checkable
                        onExpand={onExpand}
                        expandedKeys={expandedKeys}
                        autoExpandParent={autoExpandParent}
                        onCheck={onCheck}
                        checkedKeys={checkedKeys}
                        onSelect={onSelect}
                        selectedKeys={selectedKeys}
                        treeData={treeData}
                        checkStrictly={true}
                        
                    />
                    <div style={{marginTop:'20px',textAlign:'right'}}>
                        <Button type="primary" onClick={menusSend} htmlType="submit">确定</Button>
                    </div>
                     
                </div>
            </Modal>


            {/* 企业组权限弹窗弹窗 */}
            <Modal title='绑定权限组'  open={groupModalOpen1} footer={false} onCancel={()=>{setGroupModalOpen1(false)}}>
                {/* <div> */}
                      {
                        apiGroup1.map((ele,index)=>{
                          return <div key={ele.id+'div'}>
                                    <Divider orientation="left" plain>{ele.name}</Divider>
                                    <Checkbox 
                                      key={ele.id}
                                      indeterminate={indeterminate1[index]} 
                                      onChange={(val)=>{onCheckAllChange1(val,index)}} 
                                      checked={checkAll1[index]}
                                    >
                                        全选
                                    </Checkbox>
                                    <Checkbox.Group value={checkedList1[index]}  onChange={(val)=>{onChange1(val,index)}} >
                                        {
                                          ele.apis.map((item,index1)=>{
                                            return <Checkbox key={item.id} value={item.id}>{item.description}</Checkbox>
                                          })
                                        }
                                    </Checkbox.Group>
                                     {/* <CheckboxGroup options={ele.apis.map(item=>{return {value:item.id,label:item.description}})}  value={checkedList[index]} onChange={(val)=>{onChange(val,index)}} /> */}
                          </div>
                        })
                      }
    
                    <div style={{marginTop:'20px',textAlign:'right'}}>
                        <Button type="primary" htmlType="submit" onClick={()=>{sendAuthority1()}}>确定</Button>
                    </div>
                {/* </div> */}
            </Modal>

            {/* 用户组权限弹窗弹窗 */}
            <Modal title='绑定权限组'  open={groupModalOpen} footer={false} onCancel={()=>{setGroupModalOpen(false)}}>
                {/* <div> */}
                      {
                        apiGroup.map((ele,index)=>{
                          return <div key={ele.id+'div'}>
                                    <Divider orientation="left" plain>{ele.name}</Divider>
                                    <Checkbox 
                                      key={ele.id}
                                      indeterminate={indeterminate[index]} 
                                      onChange={(val)=>{onCheckAllChange(val,index)}} 
                                      checked={checkAll[index]}
                                    >
                                        全选
                                    </Checkbox>
                                    <Checkbox.Group value={checkedList[index]}  onChange={(val)=>{onChange(val,index)}} >
                                        {
                                          ele.apis.map((item,index1)=>{
                                            return <Checkbox key={item.id} value={item.id}>{item.description}</Checkbox>
                                          })
                                        }
                                    </Checkbox.Group>
                                     {/* <CheckboxGroup options={ele.apis.map(item=>{return {value:item.id,label:item.description}})}  value={checkedList[index]} onChange={(val)=>{onChange(val,index)}} /> */}
                          </div>
                        })
                      }
    
                    <div style={{marginTop:'20px',textAlign:'right'}}>
                        <Button type="primary" htmlType="submit" onClick={()=>{sendAuthority()}}>确定</Button>
                    </div>
                {/* </div> */}
            </Modal>
        </div>
    )
}
export default WtUserLevelManage